import { FC } from 'react'

const Logo: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 36">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.678 9.82a6 6 0 1 0 10.556 5.205l4.83 1.294a11 11 0 1 1-7.779-13.472l-1.294 4.83A6 6 0 0 0 9.678 9.82ZM37.199 17.125a6 6 0 1 0-10.556-5.206l-4.83-1.294a11 11 0 1 1 7.779 13.472l1.294-4.83a6 6 0 0 0 6.313-2.142Z"
      fill="#fff"
    />
    <path
      d="M3.866 31H3v4.873h.866V31ZM9.269 35.873V31h-.86v3.3L5.84 31h-.712v4.873h.859v-3.3l2.57 3.3h.713ZM11.464 31.766H9.919V31h3.955v.766H12.33v4.107h-.865v-4.107ZM14.94 35.38c.359.375.86.563 1.504.563s1.143-.188 1.498-.564c.36-.38.54-.923.54-1.629V31h-.852v2.715c0 .956-.393 1.434-1.18 1.434-.79 0-1.185-.478-1.185-1.434V31H14.4v2.75c0 .706.18 1.248.54 1.63ZM19.694 31h.866v4.873h-.866V31ZM21.222 31.766h1.545v4.107h.866v-4.107h1.544V31h-3.955v.766ZM25.827 31h.866v4.873h-.866V31ZM30.152 35.873 32.183 31h-.865l-1.565 3.76L28.2 31h-.939l2.038 4.873h.852ZM32.705 31h3.209l-.303.76h-2.028v1.265h1.544l-.273.728h-1.27v1.36h.786l-.272.76h-1.393V31ZM35.066 35.114l-.272.76h.787l.272-.76h-.787ZM40.31 36l-1.244-3.34L37.822 36H36.61l1.853-4.941h1.206L41.522 36H40.31ZM41.878 31.059H43V36h-1.122v-4.941Z"
      fill="#fff"
      fillOpacity=".5"
    />
  </svg>
)

export default Logo
