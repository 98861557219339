// import 'react-native-get-random-values'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'

export const createId = () => {
  return `${uuidv4()}`
}

export const showIfAvailable = (attribute?: string, defaultValue?: string) => {
  if (!attribute) {
    if (defaultValue) {
      return defaultValue
    }

    return ''
  }

  return attribute
}
