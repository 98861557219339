import { Keys } from 'config'
import { log } from 'shared'
import { Case, CaseStatus, Range } from 'types'
import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from '../../api.helpers'

type Options = {
  caseStatuses: CaseStatus[]
  range?: Range
}

const getCases = async (options: Options): Promise<Array<Case>> => {
  const { caseStatuses, range } = options
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id
  const userTeam = session.data.session?.user.user_metadata.team

  log('🔍 API: getCases', { onlineUserId })

  let query = apiClient
    .from<Keys.Cases, Database['public']['Tables'][Keys.Cases]>(Keys.Cases)
    .select('*')
    .eq('team', userTeam)
    .order('updated_at', { ascending: true })
  if (caseStatuses) {
    query = query.in('status', caseStatuses)
  }
  if (range) {
    query = query.range(range.from, range.to)
  }

  const { data: casesData, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getCases failed with status: ${status} and error: ${error}`)
  }

  const cases = casesData?.map(
    ({ controversy, created_at, id, name, reference, status, updated_at }) =>
      ({
        controversy,
        createdAt: created_at,
        id,
        name,
        reference,
        status,
        updatedAt: updated_at ?? created_at
      } as Case)
  )

  return cases ?? []
}

export default getCases
