import styled, { keyframes } from 'styled-components'
import { BorderRadius, Colors, FontSizes } from 'theme'

import * as Popover from '@radix-ui/react-popover'

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Root = styled(Popover.Root)`
  position: relative;
`

export const Trigger = styled.div`
  width: 100%;
`

export const Dropdown = styled(Popover.Content)`
  width: 16rem;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  animation-name: ${slideDownAndFade};

  display: flex;
  flex-direction: column;

  min-width: 0;

  background-color: white;
  border-radius: ${BorderRadius.Controls};
  border: 1px solid ${Colors.Secondary};
`

export const InputContainer = styled.div`
  padding: 0.5rem;
`

export const ListContainer = styled.ul`
  max-height: 10rem;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Label = styled.label`
  padding: 0.75rem 1.25rem;
  display: flex;
  flex: 1;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`

export const LabelText = styled.span`
  font-size: ${FontSizes.XSmall};
  line-height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 1rem;
`

export const Count = styled.span`
  font-size: ${FontSizes.XSmall};
  font-weight: bold;
`
