import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createOrUpdateRelevance } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Controversy, Relevance } from 'types'

const useCreateOrUpdateRelevance = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(createOrUpdateRelevance, {
    onMutate: async ({ controversyId, relevant, userId }) => {
      const relevanceKey = [Keys.Relevance, controversyId, userId]

      await queryClient.cancelQueries(relevanceKey)

      await queryClient.cancelQueries([Keys.Controversies])
      await queryClient.cancelQueries([Keys.ControversiesIrrelevant])
      await queryClient.cancelQueries([Keys.ControversiesRelevant])

      const previousRelevance = queryClient.getQueryData<Relevance>(relevanceKey)
      const updatedRelevance = { ...previousRelevance, relevant }

      const previousControversies =
        queryClient.getQueryData<Controversy[]>([Keys.Controversies]) ?? []
      const updatedControversies = previousControversies.map(previousControversy => {
        if (controversyId === previousControversy.id) {
          return { ...previousControversy, relevant }
        }
        return previousControversy
      })

      queryClient.setQueryData([Keys.Controversies], updatedControversies)
      queryClient.setQueryData(relevanceKey, updatedRelevance)

      return { previousControversies, previousRelevance }
    },
    onError: (error, { controversyId, userId }, context) => {
      queryClient.setQueryData([Keys.Controversies], context?.previousControversies ?? [])
      queryClient.setQueryData(
        [Keys.Relevance, controversyId, userId],
        context?.previousRelevance ?? null
      )

      logError('createRelevance failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { controversyId, userId }) => {
      queryClient.invalidateQueries([Keys.Controversies])
      queryClient.invalidateQueries([Keys.ControversiesIrrelevant])
      queryClient.invalidateQueries([Keys.ControversiesRelevant])
      queryClient.invalidateQueries([Keys.Relevance, controversyId, userId])
      queryClient.invalidateQueries([Keys.Relevance, 'count', true])
      queryClient.invalidateQueries([Keys.Relevance, 'count', false])
      queryClient.invalidateQueries([Keys.Relevance, 'count'])
      queryClient.invalidateQueries([Keys.ControversiesPaginated])
      queryClient.invalidateQueries([Keys.ControversiesPaginatedIrrelevant])
      queryClient.invalidateQueries([Keys.ControversiesPaginatedRelevant])
    }
  })
}

export default useCreateOrUpdateRelevance
