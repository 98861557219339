export const creationTimestamps = () => ({
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString()
})

export const updateTimestamps = () => ({ updated_at: new Date().toISOString() })

export const isBadResponseStatus = (status?: number) => {
  if (!status) return false

  const firstStatusNumberString = `${status}`.substring(0, 1)

  return (
    firstStatusNumberString === '3' ||
    firstStatusNumberString === '4' ||
    firstStatusNumberString === '5'
  )
}
