import styled from 'styled-components'
import { RiCloseLine } from 'react-icons/ri'
import {
  AccordionContent as AContent,
  AccordionHeader as AHeader,
  AccordionItem as AItem,
  AccordionRoot as ARoot,
  Select as BaseSelect,
  TabsList as BaseTabsList
} from 'components'
import { Colors, FontSizes } from 'theme'

const GAP = '1.5rem'

export const Wrapper = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 25rem;
  grid-gap: ${GAP};
`

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP};
`

export const Select = styled(BaseSelect)`
  min-width: 100px;
`

export const SelectBusinessUnitWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 0.5rem;
`

export const CaseBusinessUnitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`

export const ResponsibleBusinessUnitsHeadline = styled.span`
  font-size: ${FontSizes.Base};
  font-weight: 600;
  margin-bottom: 1.5rem;
`

export const SelectedBusinessUnitRow = styled.div`
  align-items: center;
  border-top: 1px solid ${Colors.Divider};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
`

export const BusinessUnitName = styled.span`
  font-size: ${FontSizes.Base};
  font-weight: 400;
`

export const DeleteIcon = styled(RiCloseLine)`
  color: ${Colors.TextLight};
  height: 1.125rem;
  transition: all 0.25s;
  width: 1.125rem;
`

export const DeleteButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  padding: 0.5rem;
  transition: all 0.25s;

  &:hover {
    ${DeleteIcon} {
      color: ${Colors.Destructive};
    }
  }
`

export const TabContentWrapper = styled.div`
  padding-top: 2rem;
`

export const NoDataLabel = styled.span`
  font-size: ${FontSizes.Small};
`

export const Heading = styled.span`
  border-bottom: 1px solid ${Colors.Divider};
  display: block;
  font-size: ${FontSizes.Small};
  font-weight: 700;
  margin: 2em 0 0;
  padding-bottom: 0.5rem;
  text-align: left;
`

export const AccordionContent = styled(AContent)``

export const AccordionHeader = styled(AHeader)``

export const AccordionItem = styled(AItem)``

export const AccordionRoot = styled(ARoot)`
  margin-top: 2rem;
`

export const AccordionTitle = styled(Heading)`
  border-bottom: none;
  margin: 2px 0.25rem 0;
  padding-bottom: 0;
`

export const TextInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
`

export const TabsList = styled(BaseTabsList)`
  margin-bottom: 2rem;
`
