import { Button, Layout, TabsContent, TabsList, TabsRoot } from 'components'
import { useLocation } from 'react-router-dom'
import { CasesClosed, CasesOpen } from './components'

const getDefaultTabName = (potentialTabName?: string | null) => {
  try {
    return potentialTabName === 'closed' ? 'closed' : 'open'
  } catch (error) {
    return 'open'
  }
}

const Cases = () => {
  const { search } = useLocation()

  return (
    <Layout componentEnd={<Button>Create Report</Button>}>
      <TabsRoot defaultValue={getDefaultTabName(new URLSearchParams(search).get('tab'))}>
        <TabsList
          tabs={[
            { name: 'Open Cases', value: 'open' },
            { name: 'Closed Cases', value: 'closed' }
          ]}
        />
        <TabsContent value="open">
          <CasesOpen />
        </TabsContent>
        <TabsContent value="closed">
          <CasesClosed />
        </TabsContent>
      </TabsRoot>
    </Layout>
  )
}

export default Cases
