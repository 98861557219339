import { log } from 'shared'
import { Regulation, SearchRegulationsParams } from 'types'
import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'
import { Database } from 'api/api.types'
import { Keys } from 'config'

type FilterWithCount = Partial<
  Record<keyof Regulation, { values: string | string[]; count: number }>
>

const countOccurrences = (
  entries: { [key: string]: string }[],
  filterKey: keyof Regulation
): FilterWithCount => {
  const filterCounts: FilterWithCount = {}

  entries.forEach(entry => {
    const filterValue = entry[filterKey]

    if (filterValue !== undefined) {
      if (!filterCounts[filterValue]) {
        filterCounts[filterValue] = {
          values: [filterValue],
          count: 1
        }
      } else {
        filterCounts[filterValue].count++
      }
    }
  })

  return filterCounts
}

type Options = {
  params?: SearchRegulationsParams
  filter: keyof Regulation
}

export const getFilterOptions = async (options: Options): Promise<FilterWithCount | null> => {
  log('🔍 API: getFilterOptions', { filter: options?.filter })

  const withSearchTerm = options.params?.search_titlefulltext

  const { data, error, status } = await (withSearchTerm
    ? apiClient.rpc('search_regulations', options?.params).select(options.filter)
    : apiClient
        .from<Keys.Regulations, Database['public']['Tables'][Keys.Regulations]>(Keys.Regulations)
        .select(options.filter))

  if (isBadResponseStatus(status)) {
    throw new Error(`getFilterOptions failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return null
  }

  const filters = countOccurrences(data, options?.filter)

  const sortedByOccurrences: FilterWithCount = Object.fromEntries(
    Object.entries(filters)
      .sort(([, a], [, b]) => b.count - a.count)
      .map(([filterValue, { values, count }]) => [filterValue, { values, count }])
  )

  return sortedByOccurrences
}
