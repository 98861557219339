import { AIGymLabel } from './AIGym.types'

const compareTwoArrays = (arr1, arr2) => {
  if (arr1?.length) {
    console.log({ arr1, arr2 })
  }
  if (arr1.length !== arr2.length) {
    return false
  }

  const intersectArr = arr1.filter(item => arr2.includes(item))
  return intersectArr.length === arr1.length
}

export const addLabelsToText = ({
  hasFocusedLabel,
  text,
  labels
}: {
  hasFocusedLabel?: boolean
  text: string
  labels: Array<AIGymLabel>
}) => {
  labels.sort((a, b) => a.start - b.start)

  const chars = text.split('')

  const textData = chars.map((char, index) => {
    const charLabels = labels.filter(label => label.start <= index && label.end > index)
    return { char, labels: charLabels, index }
  })

  const textData2: { start: number; end: number; labels: AIGymLabel[] }[] = []
  let curIndex = 0

  for (let i = 0; i < textData.length; i++) {
    if (i === curIndex) {
      const item = textData[i]
      const start = i
      let end = i

      if (i + 1 < textData.length) {
        for (let j = i + 1; j < textData.length; j++) {
          const nextData = textData[j]
          if (compareTwoArrays(item.labels, nextData.labels)) {
            end = j
          } else {
            curIndex = end + 1
            textData2.push({ start, end, labels: item.labels })
            break
          }
        }
      }
    }
  }

  let html = ''

  textData.forEach((item, i) => {
    const start = textData2.find(item => item.start === i)
    const end = textData2.find(item => item.end === i)

    const endLabels = labels.filter(label => label.end - 1 === i)

    if (!start && !end) {
      html += item.char
    } else {
      if (start) {
        if (item.labels.length) {
          for (const label of item.labels) {
            const { isFocused, color } = label
            const markStartTag = `<mark style="background-color:${
              hasFocusedLabel && !isFocused ? 'transparent' : color
            };font-weight:600;padding:2px;">`
            html += markStartTag
          }
        }
      }

      html += item.char

      if (endLabels.length) {
        for (const label of endLabels) {
          html += `<sup data-label="${label.labelName}" style="font-size:0.6875em;font-weight:normal;margin-left:2px;user-select:none;"></sup>`
        }
      }

      if (end) {
        for (const label of item.labels) {
          html += `</mark>`
        }
      }
    }
  })

  return { html }
}

export const getSelectionCharacterOffsetWithin = (element: any) => {
  const doc = element.ownerDocument || element.document
  const win = doc.defaultView || doc.parentWindow

  let start = 0
  let end = 0
  let sel

  if (typeof win.getSelection != 'undefined') {
    sel = win.getSelection()

    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0)
      const preCaretRange = range.cloneRange()

      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.startContainer, range.startOffset)
      start = preCaretRange.toString().length
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      end = preCaretRange.toString().length
    }
  }

  return { startIndex: start, endIndex: end }
}
