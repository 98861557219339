import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteCaseBusinessUnit, CreateCaseBusinessUnit } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { CaseBusinessUnits } from 'types'

export const useDeleteCaseBusinessUnit = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(deleteCaseBusinessUnit, {
    onMutate: async ({ businessUnitId, caseId }: CreateCaseBusinessUnit) => {
      const key = [Keys.CaseBusinessUnits, caseId]

      await queryClient.cancelQueries(key)
      const previousData = queryClient.getQueryData<CaseBusinessUnits>(key) ?? []
      queryClient.setQueryData<CaseBusinessUnits>(
        key,
        previousData.filter(({ businessUnitId: buId }) => businessUnitId !== buId)
      )
      return { previousData }
    },
    onError: (error, { caseId }, context) => {
      queryClient.setQueryData([Keys.CaseBusinessUnits, caseId], context?.previousData ?? [])
      logError('deleteCaseBusinessUnit failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { caseId }) => {
      queryClient.invalidateQueries([Keys.CaseBusinessUnits, caseId])
    }
  })
}
