import { Button } from 'components'
import { FC } from 'react'
import { Wrapper } from './LoadMore.styles'

type Props = {
  className?: string
  isLoading: boolean
  onLoadMore: () => void
}

const LoadMore: FC<Props> = ({ isLoading, onLoadMore, className }) => {
  return (
    <Wrapper className={className}>
      <Button isLoading={isLoading} onClick={onLoadMore} passive>
        Load more
      </Button>
    </Wrapper>
  )
}

export default LoadMore
