export const DEFAULT_FETCHER_OPTIONS = {
  cacheTime: 1000 * 60 * 5,
  disabled: false,
  keepPreviousData: false,
  staleTime: 1000 * 60 * 5
}

export const DEFAULT_FETCHER_CACHE_TIME = 1000 * 60 * 5
export const DEFAULT_FETCHER_DISABLED = false
export const DEFAULT_FETCHTER_KEEP_PREVIOUS_DATA = false
export const DEFAULT_FETCHER_STALE_TIME = 1000 * 60 * 5
