import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateRegulation } from 'api'
import { UpdateRegulation } from 'api/regulations/mutations/updateRegulation'
import { Keys } from 'config'

import { logError } from 'shared'
import { useToast } from 'stores'
import { Regulation } from 'types'

export const useUpdateRegulation = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(updateRegulation, {
    onMutate: async ({ regulationId, updatedRegulation }: UpdateRegulation) => {
      const key = [Keys.Regulations, regulationId]

      await queryClient.cancelQueries(key)
      const previousRegulation = queryClient.getQueryData<Regulation>(key) ?? []
      queryClient.setQueryData(key, { ...previousRegulation, updatedRegulation })

      return { previousRegulation, updatedRegulation }
    },
    onError: (error, { regulationId, updatedRegulation: { is_controversy, title } }, context) => {
      queryClient.setQueryData([Keys.Regulations, regulationId], context?.previousRegulation ?? [])
      logError('updateRegulation failed', { error })

      const description = is_controversy
        ? `Error moving regulation "${title}" to Reg-Feed.`
        : `Error removed regulation "${title}" from Reg-Feed.`

      showToast({ description, title: 'Error', type: 'error' })
    },
    onSuccess: (_, { updatedRegulation: { is_controversy, title } }, context) => {
      const description = is_controversy
        ? `Moved regulation "${title}" to Reg-Feed.`
        : `Removed regulation "${title}" from Reg-Feed.`

      showToast({ description, title: 'Success', type: 'success' })
    },
    onSettled: (_, __, { regulationId }) => {
      queryClient.invalidateQueries([Keys.Regulations])
      queryClient.invalidateQueries([regulationId])
    }
  })
}
