import { useQuery } from '@tanstack/react-query'
import { getRegulationsCount } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from 'data-fetcher/data-fetcher.helpers'
import { FetcherOptions } from 'data-fetcher/data-fetcher.types'
import { SearchRegulationsParams } from 'types'

export const useGetRegulationsCount = (
  params: SearchRegulationsParams,
  options?: FetcherOptions
) => {
  return useQuery(
    [
      Keys.Regulations,
      params
        ? Object.entries(params)
            .map(([key, value]) => `${key}-${value}`)
            .join('-')
        : null,
      'count-all'
    ],
    () => getRegulationsCount(params),
    {
      ...getDefaultFetcherOptions(options)
    }
  )
}
