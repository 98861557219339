import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateCase, UpdateCase } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Case } from 'types'

const useUpdateCase = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(updateCase, {
    onMutate: async ({ caseId, updatedCase }: UpdateCase) => {
      const key = [Keys.Cases, caseId]

      await queryClient.cancelQueries(key)
      const previousCase = queryClient.getQueryData<Case>(key) ?? []
      queryClient.setQueryData(key, { ...previousCase, ...updatedCase })
      return { previousCase }
    },
    onError: (error, { caseId }, context) => {
      queryClient.setQueryData([Keys.Cases, caseId], context?.previousCase ?? [])
      logError('updateCase failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { caseId }) => {
      queryClient.invalidateQueries([Keys.Cases, caseId])
      queryClient.invalidateQueries([Keys.Cases])
      queryClient.invalidateQueries([Keys.CasesPaginated])
    }
  })
}

export default useUpdateCase
