import { useQuery } from '@tanstack/react-query'
import { getColors } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetColors = (options?: FetcherOptions) => {
  return useQuery([Keys.Colors], getColors, {
    ...getDefaultFetcherOptions(options),
    onError: error => {
      logError(error)
    }
  })
}

export default useGetColors
