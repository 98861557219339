import { useQuery } from '@tanstack/react-query'
import { getCasesCount } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { CaseStatus } from 'types'

const useGetCasesCount = ({
  statuses,
  options
}: {
  statuses: CaseStatus[]
  options?: FetcherOptions
}) => {
  return useQuery(
    [Keys.Cases, ...statuses],
    () => getCasesCount([...statuses]),
    getDefaultFetcherOptions(options)
  )
}

export default useGetCasesCount
