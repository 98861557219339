import { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { RiFileUploadLine, RiTaskLine, RiCheckboxCircleLine } from 'react-icons/ri'
import styled from 'styled-components'

import { Colors, FontSizes } from 'theme'

import Loader from '../Loader'

const FileUpload: FC<{
  className?: string
  loading?: boolean
  maxSize?: number
  uploadedFilesNames?: Array<string>
  onFileDrop: (file: any) => void
}> = ({
  className,
  loading,
  maxSize = 10 * 1024 * 1024, // 10MB
  onFileDrop,
  uploadedFilesNames
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => onFileDrop(acceptedFiles),

    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/png': ['.png'],
      'text/csv': ['.csv'],
      'text/html': ['.html', '.htm']
    },
    maxSize: maxSize
  })

  return (
    <Wrapper {...getRootProps()} className={className}>
      {loading ? (
        <Loader />
      ) : isDragActive ? (
        <IconWrapper success>
          <RiCheckboxCircleLine color={Colors.Success} size={16} />
        </IconWrapper>
      ) : uploadedFilesNames?.length ? (
        <IconWrapper success>
          <RiTaskLine color={Colors.Success} size={16} />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <RiFileUploadLine color={Colors.Primary} size={16} />
        </IconWrapper>
      )}
      {loading ? null : isDragActive ? (
        <MainLabel>Drop the files here ...</MainLabel>
      ) : uploadedFilesNames?.length ? (
        <>
          <MainLabel>{uploadedFilesNames.join(', ')}</MainLabel>
          <SubLabel>Click Save to upload file(s)</SubLabel>
        </>
      ) : (
        <>
          <MainLabel>Click to upload or drag & drop</MainLabel>
          <SubLabel>PDF, CSV, XLSX, DOCX, JPG or PNG and up to 10MB</SubLabel>
        </>
      )}
      <input {...getInputProps()} />
    </Wrapper>
  )
}

export default FileUpload

const Wrapper = styled.div`
  align-items: center;
  border: 1px solid ${Colors.InputBorder};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
`

const IconWrapper = styled.div<{ success?: boolean }>`
  align-items: center;
  background: ${Colors.PrimaryLight};
  border-radius: 4px;
  display: flex;
  height: 2rem;
  margin-bottom: 0.5rem;
  justify-content: center;
  width: 2rem;

  &:hover {
    background: ${Colors.PrimaryLightHover};
  }

  ${({ success }) =>
    success &&
    `
    background: ${Colors.SuccessBackground};
  `}
`

const MainLabel = styled.span`
  color: ${Colors.TextLight};
  font-size: ${FontSizes.Small};
`

const SubLabel = styled.span`
  font-size: 0.625rem;
  color: ${Colors.TextLight};
`
