import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ComponentRightWrapper, Row, Title, Wrapper } from './Card.styles'

const Card: FC<{
  children: ReactNode
  className?: string
  componentRight?: ReactNode
  href?: string
  icon?: ReactNode
  inverted?: boolean
  title?: string
}> = ({
  children,
  className,
  componentRight: ComponentRight,
  href,
  icon: Icon,
  inverted,
  title
}) => (
  <Wrapper as={href ? Link : 'div'} className={className} $inverted={inverted} to={href}>
    {(Icon || title || ComponentRight) && (
      <Row>
        {Boolean(Icon) && Icon}
        {Boolean(title) && <Title>{title}</Title>}
        {Boolean(ComponentRight) && <ComponentRightWrapper>{ComponentRight}</ComponentRightWrapper>}
      </Row>
    )}
    {children}
  </Wrapper>
)

export default Card
