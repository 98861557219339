import { useQuery } from '@tanstack/react-query'
import { getRelevance } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetRelevance = ({
  controversy,
  options,
  user
}: {
  controversy: string
  options?: FetcherOptions
  user?: string
}) => {
  return useQuery(
    [Keys.Relevance, controversy, user ?? null],
    () => getRelevance({ controversy, user }),
    getDefaultFetcherOptions(options)
  )
}

export default useGetRelevance
