import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider, ColorsProvider, ToastProvider } from 'stores'
import AppStyles from './AppStyles'

import '@tremor/react/dist/esm/tremor.css'

const queryClient = new QueryClient()

const App = () => {
  return (
    <>
      <AppStyles />
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <ColorsProvider>
            <AuthProvider />
          </ColorsProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ToastProvider>
    </>
  )
}

export default App
