import * as Accordion from '@radix-ui/react-accordion'
import { RiArrowDownSLine } from 'react-icons/ri'
import styled, { keyframes } from 'styled-components'

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

export const AccordionRoot = styled(Accordion.Root)``

export const AccordionIcon = styled(RiArrowDownSLine)`
  height: 1.25rem;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  width: 1.25rem;
`

export const AccordionHeaderContentWrapper = styled.div``

export const AccordionTrigger = styled(Accordion.Trigger)`
  align-items: center;
  display: flex;
  width: 100%;

  &[data-state='open'] {
    ${AccordionIcon} {
      transform: rotate(180deg);
    }
  }
`

export const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`

export const AccordionItem = styled(Accordion.Item)`
  overflow: hidden;

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`
