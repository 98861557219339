import { Keys } from 'config'
import { log } from 'shared'
import { Labeling } from 'types'

import apiClient from '../../apiClient'
import { isBadResponseStatus } from '../../api.helpers'
import { Database } from '../../api.types'

const getAILabeling = async (controversyId?: string): Promise<Array<Labeling>> => {
  if (!controversyId) {
    return []
  }

  log('🔍 API: getAILabeling')

  const { data, error, status } = await apiClient
    .from<Keys.Labeling, Database['public']['Tables'][Keys.Labeling]>(Keys.Labeling)
    .select(
      `
      ai,
      creator,
      end,
      id,
      label,
      start,
      text,
      title,
      with_label:labels(
        with_color:colors(active, id, passive),
        id,
        name
      ),
      with_fingerprint:fingerprints(id, name)
    `
    )
    .eq('controversy', controversyId)
    .eq('ai', true)
    .order('start', { ascending: true })

  if (isBadResponseStatus(status)) {
    throw new Error(`getAILabeling failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return []
  }

  const defaultLabelings: Labeling[] = []

  const labelings = data?.reduce(
    (prev, { creator, end, id, label, start, text, title, with_label, with_fingerprint }) => {
      if (Boolean(text) === false && Boolean(title) === false) {
        return prev
      }

      const withLabel = Array.isArray(with_label) ? with_label?.[0] : with_label
      const withColor = Array.isArray(withLabel?.with_color)
        ? withLabel?.with_color[0]
        : withLabel?.with_color
      const withFingerprint = Array.isArray(with_fingerprint)
        ? with_fingerprint[0]
        : with_fingerprint

      const labeling: Labeling = {
        controversyId,
        color: {
          active: withColor?.active,
          id: withColor?.id,
          passive: withColor?.passive
        },
        creator,
        end,
        fingerprintId: withFingerprint?.id,
        fingerprintName: withFingerprint?.name,
        id,
        labelId: label,
        labelName: withLabel?.name,
        start,
        text: text ?? undefined,
        title: title ?? undefined
      }

      return [...prev, labeling]
    },
    defaultLabelings
  )

  return labelings ?? []
}

export default getAILabeling
