import { useInfiniteQuery } from '@tanstack/react-query'
import { getControversies } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'
import useGetOpenControversiesCount from './useGetOpenControversiesCount'

const PAGE_SIZE = 30

const useGetPaginatedControversies = (options?: FetcherOptions) => {
  const { data: count } = useGetOpenControversiesCount()
  return useInfiniteQuery(
    [Keys.ControversiesPaginated],
    ({ pageParam = 0 }) =>
      getControversies({
        range: { from: pageParam * PAGE_SIZE, to: (pageParam + 1) * PAGE_SIZE - 1 }
      }),
    {
      ...getDefaultFetcherOptions(options),
      getNextPageParam: (lastPage, pages) => {
        const amountOfFetchedItems = pages.reduce((acc, page) => acc + page.length, 0)
        if (count === 0 || (count && !(amountOfFetchedItems < count))) {
          return undefined
        }
        return pages.length
      }
    }
  )
}

export default useGetPaginatedControversies
