import { useInfiniteQuery } from '@tanstack/react-query'
import { getCases } from 'api'
import { Keys } from 'config'
import { CaseStatus } from 'types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'
import useGetCasesCount from './useGetCasesCount'

const PAGE_SIZE = 30

const useGetPaginatedCases = (filters: {
  caseStatuses: CaseStatus[]
  options?: FetcherOptions
}) => {
  const { caseStatuses, options } = filters || {}

  const { data: count } = useGetCasesCount({ statuses: caseStatuses })
  return useInfiniteQuery(
    [Keys.CasesPaginated, caseStatuses],
    ({ pageParam = 0 }) =>
      getCases({
        caseStatuses,
        range: { from: pageParam * PAGE_SIZE, to: (pageParam + 1) * PAGE_SIZE - 1 }
      }),
    {
      ...getDefaultFetcherOptions(options),
      getNextPageParam: (lastPage, pages) => {
        const amountOfFetchedItems = pages.reduce((acc, page) => acc + page.length, 0)
        if (count === 0 || (count && !(amountOfFetchedItems < count))) {
          return undefined
        }
        return pages.length
      }
    }
  )
}

export default useGetPaginatedCases
