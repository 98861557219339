import { FC, useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Session } from '@supabase/supabase-js'

import { apiClient } from 'api'
import { AuthRoutes, GuestRoutes } from 'router'

import AuthContext from './AuthContext'

const Auth: FC = () => {
  const [initialized, setInitialized] = useState(false)
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    init()

    apiClient.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  const init = async () => {
    const {
      data: { session }
    } = await apiClient.auth.getSession()
    setSession(session)
    setInitialized(true)
  }

  if (!initialized) {
    return null
  }

  return (
    <BrowserRouter>
      {session ? (
        <AuthContext.Provider
          value={{
            userId: session.user.id,
            userTeam: session.user.user_metadata.team
          }}
        >
          <AuthRoutes />
        </AuthContext.Provider>
      ) : (
        <GuestRoutes />
      )}
    </BrowserRouter>
  )
}

export default Auth
