import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { EmptyNote } from 'components'
import { useGetAllLabels } from 'data-fetcher'
import { groupBy } from 'shared'
import { FontSizes } from 'theme'
import { Label } from 'types'

import { LabelList } from './components'

const AddLabels: FC<{
  activeLabelId?: string
  onLabelClick: ({
    fingerprintId,
    fingerprintName,
    label
  }: {
    fingerprintId: string
    fingerprintName: string
    label: Label
  }) => void
}> = ({ activeLabelId, onLabelClick }) => {
  const { data: labels } = useGetAllLabels()
  const labelsByFingerprint = useMemo(
    () => groupBy(labels ?? [], ({ fingerprintName }) => fingerprintName),
    [labels]
  )

  return !labelsByFingerprint?.size ? (
    <EmptyNote copy="Create fingerprints and labels first." icon="label" />
  ) : (
    <Fingerprints>
      {Array.from(labelsByFingerprint).map(([fingerprintName, labels]) => (
        <FingerprintGroup key={`add-labels-fingerprint-group-${fingerprintName}`}>
          <FingerprintName>{fingerprintName}</FingerprintName>
          <LabelList
            activeLabelId={activeLabelId}
            labels={labels.map(({ fingerprintId, ...label }) => ({
              ...label,
              fingerprint: fingerprintId
            }))}
            onLabelClick={label =>
              onLabelClick({
                fingerprintId: labels.find(
                  ({ fingerprintName: fpName }) => fingerprintName === fpName
                )?.fingerprintId!,
                fingerprintName: fingerprintName!,
                label
              })
            }
          />
        </FingerprintGroup>
      ))}
    </Fingerprints>
  )
}

export default AddLabels

const Fingerprints = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const FingerprintGroup = styled.div``

const FingerprintName = styled.span`
  display: block;
  font-size: ${FontSizes.Small};
  font-weight: 600;
  margin-bottom: 0.75em;
`
