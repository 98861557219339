import { useQuery } from '@tanstack/react-query'
import { getRelevanceCount } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'

type Props = { relevant?: boolean } & FetcherOptions

const useGetRelevanceCount = (options?: Props) => {
  const { relevant } = options || {}
  return useQuery(
    [Keys.Relevance, 'count', relevant],
    () => getRelevanceCount({ relevant }),
    getDefaultFetcherOptions(options)
  )
}

export default useGetRelevanceCount
