import { Keys } from 'config'
import { logError } from 'shared'
import { Delabeling } from 'types'
import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export const createDelabeling = async ({ creator, labeling }: Delabeling) => {
  const { data, error } = await apiClient
    .from<Keys.Delabeling, Database['public']['Tables'][Keys.Delabeling]>(Keys.Delabeling)
    .insert({
      creator,
      labeling
    })

  if (error) {
    logError('createDelabeling failed', { errorMessage: error.message })
    throw new Error(error.message)
  }
  return data
}
