import { Button, Layout, Textarea, TextInput } from 'components'

import { Card, Note } from './Sources.styles'
import styled from 'styled-components'
import { useRequestNewSource } from 'data-fetcher'
import { useState } from 'react'
import { useToast } from 'stores'
import { Source } from 'types'
import { createId } from 'shared'

const DEFAULT_FORM = {
  category: '',
  description: '',
  identifier: '',
  language: '',
  link: '',
  name: '',
  scope: '',
  sourceType: ''
}

export const RequestNewSource = () => {
  const { isLoading, mutateAsync: requestNewSource } = useRequestNewSource()

  const [form, setForm] = useState<Omit<Source, 'id' | 'status'>>(DEFAULT_FORM)

  const { showToast } = useToast()

  const handleSubmit = async () => {
    try {
      if (!form.name) {
        showToast({
          description: 'Name is a mandatory field.',
          title: 'Missing fields',
          type: 'error'
        })
      }

      await requestNewSource({
        source: {
          ...form,
          id: createId()
        }
      })

      showToast({
        description: 'Your request was successfully submitted.',
        title: 'Success',
        type: 'success'
      })

      setForm(DEFAULT_FORM)
    } catch (error) {
      showToast({
        description: 'An error occurred. Please check your input and try again.',
        title: 'Error',
        type: 'error'
      })
    }
  }

  return (
    <Layout goBackHref="/sources" title="Request new source">
      <Card>
        <Form>
          <Row>
            <Column flex={0.75}>
              <TextInput
                label="Name *"
                onChange={name => setForm(prev => ({ ...prev, name }))}
                value={form.name}
              />
            </Column>
            <Column flex={0.25}>
              <TextInput
                label="Identifier"
                onChange={identifier => setForm(prev => ({ ...prev, identifier }))}
                value={form.identifier}
              />
            </Column>
          </Row>

          <Row>
            <Column flex={0.25}>
              <TextInput
                label="Language"
                onChange={language => setForm(prev => ({ ...prev, language }))}
                value={form.language}
              />
            </Column>
            <Column flex={0.75}>
              <TextInput
                label="Link *"
                onChange={link => setForm(prev => ({ ...prev, link }))}
                value={form.link}
              />
            </Column>
          </Row>

          <Row>
            <Textarea
              label="Description"
              onChange={description => setForm(prev => ({ ...prev, description }))}
              value={form.description}
            />
          </Row>

          <Row>
            <TextInput
              label="Geographical Scope"
              onChange={scope => setForm(prev => ({ ...prev, scope }))}
              value={form.scope}
            />
            <TextInput
              label="Source Type"
              onChange={sourceType => setForm(prev => ({ ...prev, sourceType }))}
              value={form.sourceType}
            />
            <TextInput
              label="Thematic Category"
              onChange={category => setForm(prev => ({ ...prev, category }))}
              value={form.category}
            />
          </Row>

          <Row>
            <Note>* Mandatory fields</Note>
          </Row>

          <Row>
            <Column flex={0}>
              <Button isLoading={isLoading} onClick={handleSubmit}>
                Save
              </Button>
            </Column>
          </Row>
        </Form>
      </Card>
    </Layout>
  )
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Row = styled.div`
  display: flex;
  gap: 2rem;
`

const Column = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => flex ?? 1};
`
