import { Navigate, Route, Routes } from 'react-router-dom'
import { Login } from 'pages'

const GuestRoutes = () => (
  <Routes>
    <Route element={<Login />} path="/" />
    <Route element={<Navigate replace to="/" />} path="*" />
  </Routes>
)

export default GuestRoutes
