import { Keys } from 'config'
import { logError } from 'shared'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type DeleteCaseBusinessUnit = { businessUnitId: string; caseId: string }

export const deleteCaseBusinessUnit = async ({
  businessUnitId,
  caseId
}: DeleteCaseBusinessUnit) => {
  const { data, error } = await apiClient
    .from<Keys.CaseBusinessUnits, Database['public']['Tables'][Keys.CaseBusinessUnits]>(
      Keys.CaseBusinessUnits
    )
    .delete()
    .eq('case', caseId)
    .eq('unit', businessUnitId)

  if (error) {
    logError('deleteCaseBusinessUnit failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}
