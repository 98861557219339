import styled from 'styled-components'

import { AccordionRoot, AccordionItem, AccordionHeader, AccordionContent, Button } from 'components'
import { Colors } from 'theme'

export const StyledAccordionRoot = styled(AccordionRoot)`
  flex: 1;
`

export const StyledAccordionItem = styled(AccordionItem)``
export const StyledAccordionHeader = styled(AccordionHeader)`
  width: 100%;
  display: flex;
  padding: 1rem 0;
  flex: 1;
`

export const AccordeonHeaderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`

export const AccordeonHeaderLabelText = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 21px;
`

export const FilterCounter = styled.span`
  background: ${Colors.Primary};
  font-weight: bold;
  font-size: 10px;
  color: ${Colors.Surface};
  border-radius: 9999px;
  padding: 4px 8px 3px;
`

export const ClearButton = styled.span`
  margin-right: 0.25rem;
`

export const StyledAccordionContent = styled(AccordionContent)``

export const StyledButton = styled(Button)`
  padding: 0 0.5rem;
`
