import { Keys } from 'config'
import { log as logger } from 'shared'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from 'api/api.helpers'

const deleteLabel = async (labelId: string) => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user?.id

  logger('API: deleteLabel', { onlineUserId, labelId })

  const { status } = await apiClient
    .from<Keys.Labels, Database['public']['Tables'][Keys.Labels]>(Keys.Labels)
    .delete()
    .eq('id', labelId)

  if (isBadResponseStatus(status)) {
    throw new Error(`deleteLabel responded with bad status code: ${status}`)
  }
}

export default deleteLabel
