import { useQuery } from '@tanstack/react-query'
import { GetSources, getSources } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetSources = ({ status }: GetSources, options?: FetcherOptions) => {
  return useQuery(
    [Keys.Sources, status],
    () => getSources({ status }),
    getDefaultFetcherOptions(options)
  )
}

export default useGetSources
