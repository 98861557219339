import { FC } from 'react'
import styled from 'styled-components'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useDeleteFingerprint } from 'data-fetcher'
import { Colors } from 'theme'

const DeleteFingerprintButton: FC<{ fingerprint: string }> = ({ fingerprint }) => {
  const { mutate: deleteFingerprint } = useDeleteFingerprint()

  return (
    <button onClick={() => deleteFingerprint(fingerprint)}>
      <Icon size={17} />
    </button>
  )
}

export default DeleteFingerprintButton

const Icon = styled(RiDeleteBinLine)`
  color: ${Colors.TextLight};

  &:hover {
    color: ${Colors.Destructive};
  }
`
