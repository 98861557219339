import { FC } from 'react'
import { Textarea as Input, Label, Wrapper } from './Textarea.styles'

export const Textarea: FC<{
  className?: string
  cols?: number
  inverted?: boolean
  label?: string
  onChange?: (input: string) => void
  onSubmit?: () => void
  placeholder?: string
  renderAs?: string
  size?: 'base' | 'large'
  type?: string
  value?: string
}> = ({
  className,
  cols = 2,
  inverted,
  label,
  onChange,
  renderAs = 'input',
  onSubmit,
  size,
  value,
  ...props
}) => (
  <Wrapper className={className}>
    {label && <Label $inverted={inverted}>{label}</Label>}
    <Input
      cols={cols}
      onChange={e => onChange?.(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onSubmit?.()
        }
      }}
      value={value ?? ''}
      {...props}
    />
  </Wrapper>
)
