import { FC, ReactNode } from 'react'

import { AccordionHeaderContentWrapper, AccordionIcon, AccordionTrigger } from './Accordion.styles'

export const AccordionHeader: FC<{ children?: ReactNode; className?: string }> = ({
  children,
  className
}) => {
  return (
    <AccordionTrigger>
      <AccordionHeaderContentWrapper className={className}>
        {children}
      </AccordionHeaderContentWrapper>
      <AccordionIcon />
    </AccordionTrigger>
  )
}
