/**
 * ! Custom page, differs from dbag
 * */
import { useState } from 'react'
import {
  Content,
  Form,
  Header,
  HeaderText,
  Headline,
  Logo,
  SubmitButton,
  TextInput,
  Wrapper
} from '../Login.styles'
import { useSignUpMutation } from 'data-fetcher'

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [team, setTeam] = useState('c3d5a28c-52f4-48ec-9d3c-e85af9f42b0d')

  const { isLoading: isSigningUp, mutate: signUp } = useSignUpMutation()

  const handleSignUp = () => {
    signUp({ email, password, team })
  }

  return (
    <Wrapper>
      <Content>
        <Header>
          <Logo />

          <HeaderText>
            <Headline>Request access</Headline>
          </HeaderText>
        </Header>

        <Form>
          <TextInput
            inverted
            label="Username"
            onChange={input => setEmail(input)}
            placeholder="user@provider.com"
            size="large"
            type="text"
            value={email}
          />

          <TextInput
            inverted
            label="Password"
            onChange={input => setPassword(input)}
            placeholder="******"
            size="large"
            type="password"
            value={password}
          />

          <TextInput
            inverted
            label="Team"
            onChange={input => setTeam(input)}
            placeholder="Team Id"
            size="large"
            type="text"
            value={team}
          />
          <SubmitButton isLoading={isSigningUp} onClick={handleSignUp} size="large">
            Request
          </SubmitButton>
        </Form>
      </Content>
    </Wrapper>
  )
}

export default Register
