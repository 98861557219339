/**
 * ! Custom page, differs from dbag
 * */

import styled from 'styled-components'
import { Button, Logo as BaseLogo, TextInput as BaseTextInput } from 'components'
import { Colors } from 'theme'

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${Colors.Navigation};
  background-image: url('/bg.jpg');
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 1rem;
  width: 100%;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 3rem;
  margin-bottom: 10vh;
  max-width: 22.5rem;
  width: 100%;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Logo = styled(BaseLogo)`
  height: 3rem;
  width: auto;
`

export const HeaderText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Headline = styled.span`
  color: #fff;
  font-size: 1.625rem;
  font-weight: 600;
  text-align: center;
`

export const Subline = styled.span`
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
  transition: all 0.3s;

  a {
    color: inherit;
  }

  &:hover {
    opacity: 0.85;
    text-decoration: underline;
  }
`

export const Form = styled.div`
  align-items: center;
  background-color: #162236;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  width: 100%;
`

export const TextInput = styled(BaseTextInput)`
  width: 100%;
`

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;
`

export const ForgotPassword = styled.a`
  color: #fff;
  font-size: 0.875rem;
  margin-top: 1rem;
  opacity: 0.5;

  &:hover {
    opacity: 0.85;
    text-decoration: underline;
  }
`
