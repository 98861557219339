import styled from 'styled-components'
import { FontSizes, Colors, BorderRadius } from 'theme'

import { RiCloseFill } from 'react-icons/ri'

export const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding-left: 6px;
  border-radius: ${BorderRadius.Tiles};
  background-color: ${Colors.Base};
  overflow: hidden;
  white-space: nowrap;
`

export const Label = styled.span`
  font-size: ${FontSizes.BaseButton};
  font-weight: 600;
  color: ${Colors.Text};
  text-overflow: ellipsis;
  min-width: 0;
  overflow: hidden;
`

export const DeleteIcon = styled(RiCloseFill)``

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  padding: 6px;
`
