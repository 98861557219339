import { useQuery } from '@tanstack/react-query'
import { getControversiesWithRelevance, ControversiesWithRelevanceProps } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

type Props = ControversiesWithRelevanceProps & FetcherOptions

export const useGetControversiesWithRelevance = ({ relevant, ...options }: Props) => {
  return useQuery(
    [relevant ? Keys.ControversiesRelevant : Keys.ControversiesIrrelevant],
    () => getControversiesWithRelevance({ relevant }),
    getDefaultFetcherOptions(options)
  )
}
