import { FC, useState } from 'react'
import { RiFingerprintLine } from 'react-icons/ri'

import { Button, Dialog, Row, TextInput } from 'components'
import { useCreateLabels, useDeleteLabel, useGetLabelsForFingerprint } from 'data-fetcher'
import { useAuth, useColors } from 'stores'
import { Colors } from 'theme'
import { Fingerprint as FingerprintType } from 'types'

import { DeleteFingerprintButton } from './components'
import {
  DeleteButton,
  DeleteLabelDialogFooter,
  DeleteLabelDialogContent,
  DeleteLabelDialogText,
  DeleteLabelDialogTitle,
  Heading,
  Label,
  Labels,
  Wrapper
} from './Fingerprint.styles'

const Fingerprint: FC<{ fingerprint: FingerprintType }> = ({ fingerprint }) => {
  const { id, name } = fingerprint

  const { userId, userTeam } = useAuth()

  const { getNextLabelColorId } = useColors()

  const [newLabelName, setNewLabelName] = useState('')
  const [labelIdToDelete, setLabelIdToDelete] = useState<string | undefined>()

  const { mutate: createLabels } = useCreateLabels()
  const { mutate: deleteLabel } = useDeleteLabel()
  const { data: labels } = useGetLabelsForFingerprint({ fingerprint: id })

  const handleDelete = (label: string) => {
    deleteLabel({ fingerprint: id, label })
    setLabelIdToDelete(undefined)
  }

  const handleSave = () => {
    createLabels([
      {
        color: getNextLabelColorId(),
        creator: userId,
        fingerprint: id,
        name: newLabelName,
        team: userTeam
      }
    ])

    setNewLabelName('')
  }

  const hasLabels = Boolean(labels?.length)

  return (
    <Wrapper
      componentRight={labels?.length === 0 && <DeleteFingerprintButton fingerprint={id} />}
      icon={<RiFingerprintLine color={Colors.Primary} size={16} />}
      title={name}
    >
      <Row alignItems="flex-end" gap="0.5rem">
        <TextInput
          label="Add Label"
          onChange={input => setNewLabelName(input)}
          onSubmit={handleSave}
          value={newLabelName}
        />
        <Button onClick={handleSave} passive>
          Add
        </Button>
      </Row>

      {hasLabels && <Heading>Labels</Heading>}
      {hasLabels && (
        <Labels>
          {labels!.map(({ color, id, name }) => (
            <Label
              active={false}
              color={color}
              id={id}
              inactive={false}
              key={`label-${id}`}
              name={name}
              onClose={() => setLabelIdToDelete(id)}
            />
          ))}
        </Labels>
      )}

      <Dialog
        contentComponent={
          <DeleteLabelDialogContent>
            <DeleteLabelDialogTitle>Delete Label</DeleteLabelDialogTitle>
            <DeleteLabelDialogText>
              Are you sure you want to delete a label of your fingerprint?
            </DeleteLabelDialogText>
          </DeleteLabelDialogContent>
        }
        footerComponent={
          <DeleteLabelDialogFooter>
            <DeleteButton
              onClick={() => {
                handleDelete(labelIdToDelete!)
              }}
            >
              Delete
            </DeleteButton>
            <Button hollow onClick={() => setLabelIdToDelete(undefined)}>
              Cancel
            </Button>
          </DeleteLabelDialogFooter>
        }
        onOpenChange={(isOpen: boolean) => {
          if (!isOpen) {
            setLabelIdToDelete(undefined)
          }
        }}
        open={Boolean(labelIdToDelete)}
      />
    </Wrapper>
  )
}

export default Fingerprint
