import { Keys } from 'config'
import { log } from 'shared'

import apiClient from '../../apiClient'
import { isBadResponseStatus } from '../../api.helpers'

/**
 * Returns user ids for user who labeled the given controversy
 */
const getUsersWithLabeling = async (controversyId?: string): Promise<Array<string>> => {
  const session = await apiClient.auth.getSession()
  const userTeam = session.data.session?.user.user_metadata.team

  if (!controversyId) {
    return []
  }

  log('🔍 API: getUsersWithLabeling')

  const { data, error, status } = await apiClient
    .from(Keys.ControversyLabelingUsers)
    .select('creator')
    .eq('controversy', controversyId)
    .eq('team', userTeam)

  if (isBadResponseStatus(status)) {
    throw new Error(`getUsersWithLabeling failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return []
  }

  return data.map(({ creator }) => creator)
}

export default getUsersWithLabeling
