import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createNote, CreateNote } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Note } from 'types'

const useCreateNote = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(createNote, {
    onMutate: async ({ caseId, note }: CreateNote) => {
      const key = [Keys.Notes, caseId, note.public ? 'public' : 'private']

      await queryClient.cancelQueries(key)
      const previousNotes = queryClient.getQueryData<Note[]>(key) ?? []
      queryClient.setQueryData(key, [...previousNotes, note])
      return { previousNotes }
    },
    onError: (error, { caseId, note }, context) => {
      queryClient.setQueryData(
        [Keys.Notes, caseId, note.public ? 'public' : 'private'],
        context?.previousNotes ?? []
      )
      logError('createNote failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { caseId, note }) => {
      queryClient.invalidateQueries([Keys.Notes, caseId, note.public ? 'public' : 'private'])
    }
  })
}

export default useCreateNote
