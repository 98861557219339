import { Label } from 'types'
import { Keys } from 'config'

import { log } from 'shared'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from 'api/api.helpers'

type LabelWithFingerprintName = Omit<Label, 'fingerprint'> & {
  fingerprintId: string
  fingerprintName?: string
}

const getAllLabels = async (): Promise<Array<LabelWithFingerprintName>> => {
  const session = await apiClient.auth.getSession()
  const userTeam = session.data.session?.user.user_metadata.team

  log('🔍 API: getAllLabels')

  const {
    data: labelsData,
    error,
    status
  } = await apiClient
    .from<Keys.Labels, Database['public']['Tables'][Keys.Labels]>(Keys.Labels)
    .select(
      `
      color,
      creator,
      fingerprint,
      id,
      name,
      team,
      with_color:colors(active, passive),
      with_fingerprint:fingerprints!labels_fingerprint_fkey(name)
    `
    )
    .eq('team', userTeam)

  if (isBadResponseStatus(status)) {
    throw new Error(`getAllLabels failed with status: ${status} and error: ${error}`)
  }

  const labels = labelsData?.map(
    ({ color, creator, fingerprint, id, name, team, with_color, with_fingerprint }) =>
      ({
        color: {
          active: Array.isArray(with_color) ? with_color?.[0]?.active : with_color?.active,
          id: color,
          passive: Array.isArray(with_color) ? with_color?.[0]?.passive : with_color?.passive
        },
        creator,
        fingerprintId: fingerprint,
        fingerprintName: Array.isArray(with_fingerprint)
          ? with_fingerprint?.[0]?.name
          : with_fingerprint?.name,

        id,
        name,
        team
      } as LabelWithFingerprintName)
  )

  return labels ?? []
}

export default getAllLabels
