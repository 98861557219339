import styled from 'styled-components'
import { Colors, FontSizes } from 'theme'

export const NotesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Note = styled.div`
  border-bottom: 1px solid ${Colors.Divider};
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding-bottom: 1rem;
`

export const NoteDate = styled.span`
  font-size: ${FontSizes.XSmall};
  font-weight: 600;
`

export const NoteText = styled.span`
  font-size: ${FontSizes.Base};
  line-height: 1.4;
`

export const TextArea = styled.textarea`
  border-color: ${Colors.InputBorder};
  line-height: 1.4;
  margin: 1rem 0;
  outline: none;
  padding: 0.75rem 1rem;
  width: 100%;

  ::placeholder {
    color: ${Colors.Placeholder};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${Colors.Placeholder};
  }

  ::-ms-input-placeholder {
    color: ${Colors.Placeholder};
  }

  &:focus-within {
    border-color: ${Colors.Text};
  }
`
