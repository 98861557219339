import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createLabels } from 'api'
import type { CreateLabels } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Label } from 'types'

const useCreateLabels = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(createLabels, {
    onMutate: async (labels: CreateLabels) => {
      await queryClient.cancelQueries([Keys.Labels])
      const previousLabels = queryClient.getQueryData<Label[]>([Keys.Labels]) ?? []
      queryClient.setQueryData([Keys.Labels], [...previousLabels, ...labels])
      return { previousLabels }
    },
    onError: (error, _, context) => {
      queryClient.setQueryData([Keys.Labels], context?.previousLabels ?? [])
      logError('createLabels failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: () => {
      queryClient.invalidateQueries([Keys.Labels])
      showToast({ description: 'Label added successfully', title: 'Success ✅' })
    }
  })
}

export default useCreateLabels
