import { useQuery } from '@tanstack/react-query'
import { getTopLabels } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetTopLabels = (options?: FetcherOptions) => {
  return useQuery([Keys.TopLabels], getTopLabels, getDefaultFetcherOptions(options))
}

export default useGetTopLabels
