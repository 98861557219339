import { FC, RefObject, useEffect, useMemo, useRef } from 'react'
import { addLabelsToText, getSelectionCharacterOffsetWithin } from './AIGym.helpers'
import { AIGymLabel } from './AIGym.types'

const AIGym: FC<{
  className?: string
  activeLabelId?: string
  hasFocusedLabel?: boolean
  /** `start` and `end` refer to original text without added `<mark>` */
  highlightedTexts?: Array<AIGymLabel>
  highlightingEnabled: boolean
  isBold?: boolean
  onTextHighlight: ({
    endIndex,
    highlightedText,
    startIndex
  }: {
    endIndex: number
    highlightedText: string
    startIndex: number
  }) => void
  paragraphRef?: RefObject<HTMLParagraphElement>
  text: string
}> = ({
  activeLabelId,
  className,
  hasFocusedLabel,
  highlightedTexts,
  highlightingEnabled,
  isBold,
  onTextHighlight,
  paragraphRef,
  text
}) => {
  const textRef = paragraphRef ?? useRef<HTMLParagraphElement | null>(null)
  const { html: labeledText } = useMemo(
    () =>
      addLabelsToText({
        hasFocusedLabel,
        labels: highlightedTexts ?? [],
        text
      }),
    [highlightedTexts, text]
  )

  useEffect(() => {
    const handleMarking = () => {
      const selection = window.getSelection()

      if (selection) {
        const { startIndex, endIndex } = getSelectionCharacterOffsetWithin(textRef.current)
        const highlightedText = selection.toString()

        if (highlightingEnabled && highlightedText && text.includes(highlightedText)) {
          onTextHighlight({ endIndex, highlightedText, startIndex })
        }
      }
    }

    textRef?.current?.addEventListener('mouseup', handleMarking)

    return () => {
      textRef?.current?.removeEventListener('mouseup', handleMarking)
    }
  }, [activeLabelId, highlightingEnabled, highlightedTexts])

  return isBold ? (
    <b
      className={className ?? 'ai-gym-text'}
      dangerouslySetInnerHTML={{ __html: labeledText }}
      ref={textRef}
    />
  ) : (
    <span
      className={className ?? 'ai-gym-text'}
      dangerouslySetInnerHTML={{ __html: labeledText }}
      ref={textRef}
    />
  )
}

export default AIGym
