import { Keys } from 'config'
import { log } from 'shared'
import { Document } from 'types'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from '../../api.helpers'

export type GetDocuments = { caseId: string; visibility: 'private' | 'public' }

const getDocuments = async ({ caseId, visibility }: GetDocuments): Promise<Array<Document>> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getDocuments', { onlineUserId })

  const query = apiClient
    .from<Keys.Documents, Database['public']['Tables'][Keys.Documents]>(Keys.Documents)
    .select('*')
    .eq('case', caseId)
    .eq('public', visibility === 'public' ? true : false)

  if (visibility === 'private') {
    query.eq('creator', onlineUserId || '')
  }

  const { data: documentsData, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getDocuments failed with status: ${status} and error: ${error}`)
  }

  const documents = documentsData?.map(
    ({ created_at, description, id, name, url }) =>
      ({
        createdAt: created_at,
        description,
        id,
        name,
        url
      } as Document)
  )

  return documents ?? []
}

export default getDocuments
