import { Keys } from 'config'
import { logError } from 'shared'
import apiClient from '../../apiClient'

const signUp = async ({
  email,
  password,
  team
}: {
  email: string
  password: string
  team: string
}) => {
  const { data, error } = await apiClient.auth.signUp({
    email,
    options: { data: { team } }, // auth.users.user_metadata
    password
  })

  if (error) {
    logError('signUp failed', { errorMessage: error.message, errorName: error.name })
    throw new Error(error.message)
  }

  if (!data.user) {
    logError('signUp failed - no user')
    throw new Error()
  }

  await apiClient.from(Keys.Profiles).insert({ id: data.user.id, team })

  return data
}

export default signUp
