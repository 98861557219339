import { Keys } from 'config'
import { log } from 'shared'
import { Color } from 'types'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from '../../api.helpers'

const getColors = async (): Promise<Array<Color>> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getColors', { onlineUserId })

  const {
    data: colorsData,
    error,
    status
  } = await apiClient
    .from<Keys.Colors, Database['public']['Tables'][Keys.Colors]>(Keys.Colors)
    .select()

  if (isBadResponseStatus(status)) {
    throw new Error(`getColors failed with status: ${status} and error: ${error}`)
  }

  const colors = colorsData?.map(({ active, id, passive }) => ({ active, id, passive } as Color))

  return colors ?? []
}

export default getColors
