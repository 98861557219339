import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createLabeling } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Labeling } from 'types'

const useCreateLabeling = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(createLabeling, {
    onMutate: async (labelings: Labeling[]) => {
      const controversyId = labelings[0]!.controversyId

      const key = [Keys.Labeling, controversyId]
      await queryClient.cancelQueries(key)
      const previousLabeling = queryClient.getQueryData<Labeling[]>(key) ?? []
      queryClient.setQueryData(key, [...previousLabeling, ...labelings])

      return { previousLabeling }
    },
    onError: (error, labelings, context) => {
      queryClient.setQueryData(
        [Keys.Labeling, labelings[0]!.controversyId],
        context?.previousLabeling ?? []
      )
      logError('createLabeling failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, labelings) => {
      queryClient.invalidateQueries([Keys.Labeling, labelings[0]!.controversyId])
      queryClient.invalidateQueries([Keys.Controversies])
    }
  })
}

export default useCreateLabeling
