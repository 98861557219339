import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import { AccordionContent, AccordionItem, AccordionRoot, EmptyNote, Label } from 'components'
import {
  useCreateDelabeling,
  useCreateLabeling,
  useGetAILabeling,
  useGetDelabeling
} from 'data-fetcher'
import { createId, groupBy } from 'shared'
import { Colors } from 'theme'
import { useHover } from '@intuitive-ai/hooks'

import {
  AccordionHeader,
  AcceptAllButton,
  AcceptAllCheckIcon,
  AcceptAllCheckIconWrapper,
  AcceptButtonLabel,
  AiSuggestionsIcon,
  Fingerprint,
  Group,
  Groups,
  Headline,
  IconButton,
  IconButtons,
  Row,
  Table,
  TableBody,
  TableData,
  TableDataInnerWrapper,
  TableHead,
  TableHeading,
  TableRow
} from './SuggestedLabels.styles'
import { Labeling } from 'types'
import { useAuth } from 'stores'
import { useQueryClient } from '@tanstack/react-query'
import { Keys } from 'config'

const HoverableTableRow: FC<PropsWithChildren & { onHoverToggle: (hovering: boolean) => void }> = ({
  children,
  onHoverToggle
}) => {
  const { ref, value } = useHover<HTMLTableRowElement>()

  useEffect(() => {
    onHoverToggle(value)
  }, [value])

  return <TableRow ref={ref}>{children}</TableRow>
}

const SuggestedLabels: FC<{
  ai?: boolean
  controversyId: string
  onLabelingHovered: (options: { hovered: boolean; labeling?: string }) => void
  userLabeling: Labeling[] | null
}> = ({ ai, controversyId, onLabelingHovered, userLabeling }) => {
  const { userId } = useAuth()

  const { mutate: createDelabeling } = useCreateDelabeling()
  const { mutate: createLabeling } = useCreateLabeling()

  const { data: labels, isLoading } = useGetAILabeling({
    controversyId
  })
  const { data: delabeling } = useGetDelabeling({ controversyId })

  const queryCache = useQueryClient()

  const aiLabelsWithoutLabelsAlreadyAppliedByUser = useMemo(
    () =>
      labels?.filter(
        ({ controversyId, end, id, labelId, start, text, title }) =>
          !delabeling?.find(({ labeling }) => labeling === id) &&
          !userLabeling?.find(
            userLabeling =>
              userLabeling.controversyId === controversyId &&
              userLabeling.end === end &&
              userLabeling.labelId === labelId &&
              userLabeling.start === start &&
              ((userLabeling.text && userLabeling.text === text) ||
                (userLabeling.title && userLabeling.title === title))
          )
      ),
    [delabeling, labels, userLabeling]
  )

  const labelsByFingerprint = useMemo(
    () =>
      groupBy(
        aiLabelsWithoutLabelsAlreadyAppliedByUser ?? [],
        ({ fingerprintName }) => fingerprintName
      ),
    [aiLabelsWithoutLabelsAlreadyAppliedByUser]
  )

  const acceptAllLabelings = () => {
    if (aiLabelsWithoutLabelsAlreadyAppliedByUser) {
      createLabeling(
        aiLabelsWithoutLabelsAlreadyAppliedByUser.map(labeling => ({
          ...labeling,
          creator: userId,
          suggested_by_ai: true
        }))
      )
    }
  }

  return (
    <>
      {isLoading || !aiLabelsWithoutLabelsAlreadyAppliedByUser?.length ? (
        <EmptyNote copy="No labels to apply." icon="label" />
      ) : (
        <Groups>
          {Array.from(labelsByFingerprint).map(([fingerprintName, labels], i) => (
            <Group key={`suggested-labels-fingerprint-group-${fingerprintName}-${i}`}>
              <AccordionRoot collapsible defaultValue="suggested-labels" type="single">
                <AccordionItem value="suggested-labels">
                  <AccordionHeader>
                    <Row>
                      <AiSuggestionsIcon />
                      <Headline>AI Suggestion</Headline>
                    </Row>
                  </AccordionHeader>
                  <AccordionContent>
                    <Fingerprint>{fingerprintName}</Fingerprint>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeading>Label</TableHeading>
                          <TableHeading>Text</TableHeading>
                          <TableHeading></TableHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {labels.map(
                          (
                            {
                              controversyId,
                              color,
                              fingerprintId,
                              fingerprintName,
                              id,
                              labelId,
                              labelName,
                              start,
                              end,
                              text,
                              title
                            },
                            i
                          ) => (
                            <HoverableTableRow
                              onHoverToggle={hovered => {
                                onLabelingHovered({ hovered, labeling: id })
                              }}
                              key={`available-labels-${fingerprintName}-${labelName}-${text}-${title}-${i}`}
                            >
                              <TableData>
                                <TableDataInnerWrapper>
                                  <Label
                                    color={color}
                                    id={labelId}
                                    isAiLabel={ai}
                                    name={labelName}
                                  />
                                </TableDataInnerWrapper>
                              </TableData>
                              <TableData>{text ?? title}</TableData>
                              <TableData>
                                <IconButtons>
                                  <IconButton
                                    $bg={Colors.SuccessBackground}
                                    $color={Colors.Success}
                                    onClick={() => {
                                      createLabeling([
                                        {
                                          controversyId,
                                          color,
                                          creator: userId,
                                          fingerprintId,
                                          fingerprintName,
                                          id: createId(),
                                          labelId,
                                          labelName,
                                          start,
                                          end,
                                          suggested_by_ai: true,
                                          text,
                                          title
                                        }
                                      ])

                                      queryCache.invalidateQueries({
                                        exact: false,
                                        queryKey: [Keys.Labeling, controversyId]
                                      })
                                    }}
                                  >
                                    <RiCheckLine />
                                  </IconButton>
                                  <IconButton
                                    $bg={Colors.DestructiveBackground}
                                    $color={Colors.Destructive}
                                    onClick={() => {
                                      createDelabeling({
                                        controversy: controversyId,
                                        creator: userId,
                                        labeling: id
                                      })
                                    }}
                                  >
                                    <RiCloseLine />
                                  </IconButton>
                                </IconButtons>
                              </TableData>
                            </HoverableTableRow>
                          )
                        )}
                      </TableBody>
                    </Table>

                    {(aiLabelsWithoutLabelsAlreadyAppliedByUser?.length ?? 0) > 0 && (
                      <AcceptAllButton onClick={acceptAllLabelings}>
                        <AcceptAllCheckIconWrapper>
                          <AcceptAllCheckIcon size={16} />
                        </AcceptAllCheckIconWrapper>
                        <AcceptButtonLabel>
                          Accept{aiLabelsWithoutLabelsAlreadyAppliedByUser.length > 1 ? `All` : ''}{' '}
                          ({aiLabelsWithoutLabelsAlreadyAppliedByUser.length}) Label
                          {aiLabelsWithoutLabelsAlreadyAppliedByUser.length > 1 ? 's' : ''}
                        </AcceptButtonLabel>
                      </AcceptAllButton>
                    )}
                  </AccordionContent>
                </AccordionItem>
              </AccordionRoot>
            </Group>
          ))}
        </Groups>
      )}
    </>
  )
}

export default SuggestedLabels
