import { FC, useState } from 'react'

import { FiX } from 'react-icons/fi'
import { RiCheckLine } from 'react-icons/ri'

import { Button, Dialog, TextInput, Tooltip } from 'components'
import { useCreateOrUpdateRelevance, useCreateCase, useGetRelevance } from 'data-fetcher'
import { useAuth } from 'stores'
import { Colors } from 'theme'
import { Controversy, CaseStatus } from 'types'
import {
  Buttons,
  DialogButtons,
  DialogContent,
  DialogHeadline,
  DialogInputs,
  DialogOr
} from '../../TextWithLabels.styles'
import { RelevancyIndicator } from './RelevancyInput.styles'

type Props = {
  controversy: Controversy
  readOnly?: boolean
  userId?: string
}

const RelevancyInput: FC<Props> = ({ controversy, readOnly, userId }) => {
  const { id } = controversy
  const { userId: currentUserId } = useAuth()

  const { mutate: createOrUpdateRelevance } = useCreateOrUpdateRelevance()
  const { mutate: createCase } = useCreateCase()

  const { data: relevance } = useGetRelevance({
    controversy: controversy.id,
    user: userId ?? currentUserId
  })

  const relevant = relevance?.relevant

  const [newCaseName, setNewCaseName] = useState('')
  const [newCaseReference, setNewCaseReference] = useState('')
  const [updateRelevancyEnabled, setUpdateRelevancyEnabled] = useState(false)

  const handleAddCase = () => {
    createCase({
      name: newCaseName,
      reference: newCaseReference,
      controversy: id,
      status: CaseStatus.New
    })
    createOrUpdateRelevance({
      userId: userId ?? currentUserId,
      controversyId: id,
      relevant: true
    })
  }

  const handleAcceptRelevance = () => {
    setUpdateRelevancyEnabled(false)

    createOrUpdateRelevance({
      userId: userId ?? currentUserId,
      controversyId: id,
      relevant: true
    })
  }

  const handleDenyRelevance = () => {
    setUpdateRelevancyEnabled(false)

    createOrUpdateRelevance({
      userId: userId ?? currentUserId,
      controversyId: id,
      relevant: false
    })
  }

  if (updateRelevancyEnabled) {
    return (
      <Buttons>
        <Button
          as="div"
          iconStart={
            <RiCheckLine
              color={Colors.TextLight}
              size={18}
              style={{ margin: '0 0.375rem 2px 0' }}
            />
          }
          onClick={handleAcceptRelevance}
        >
          Relevant
        </Button>

        <Button
          onClick={handleDenyRelevance}
          hollow
          iconStart={
            <FiX
              color={Colors.TextLight}
              size={16}
              strokeWidth="2.75"
              style={{ margin: '0 0.125rem 2px 0' }}
            />
          }
        >
          Not Relevant
        </Button>
      </Buttons>
    )
  }

  if (relevant) {
    if (readOnly) {
      return (
        <RelevancyIndicator relevant={relevant}>
          <RiCheckLine color={Colors.Primary} size={18} /> Relevant
        </RelevancyIndicator>
      )
    }

    return (
      <Tooltip label="Click to update relevancy">
        <RelevancyIndicator
          clickEnabled
          onClick={() => setUpdateRelevancyEnabled(true)}
          relevant={relevant}
        >
          <RiCheckLine color={Colors.Primary} size={18} /> Relevant
        </RelevancyIndicator>
      </Tooltip>
    )
  }

  if (relevant === false) {
    if (readOnly) {
      return (
        <RelevancyIndicator relevant={relevant}>
          <FiX color={Colors.TextLight} size={16} strokeWidth="2.75" /> Not Relevant
        </RelevancyIndicator>
      )
    }

    return (
      <Tooltip label="Click to update relevancy">
        <RelevancyIndicator
          clickEnabled
          onClick={() => setUpdateRelevancyEnabled(true)}
          relevant={relevant}
        >
          <FiX color={Colors.TextLight} size={16} strokeWidth="2.75" /> Not Relevant
        </RelevancyIndicator>
      </Tooltip>
    )
  }

  if (readOnly) {
    return null
  }

  return (
    <Buttons>
      <Dialog
        contentComponent={
          <DialogContent>
            <DialogHeadline>Create a new Case</DialogHeadline>

            <DialogInputs>
              <TextInput
                label="Case Name"
                onChange={input => setNewCaseName(input)}
                onSubmit={handleAddCase}
                placeholder="Descriptive Case Name"
                value={newCaseName}
              />
              <TextInput
                label="Case Reference"
                onChange={input => setNewCaseReference(input)}
                onSubmit={handleAddCase}
                placeholder="Unique identifier or internal reference"
                value={newCaseReference}
              />
            </DialogInputs>

            <DialogButtons>
              <Button onClick={handleAddCase}>Create Case & Continue</Button>
              <DialogOr>Or</DialogOr>
              <Button hollow onClick={handleAcceptRelevance}>
                Continue without new Case
              </Button>
            </DialogButtons>
          </DialogContent>
        }
        triggerComponent={
          <Button
            as="div"
            iconStart={
              <RiCheckLine
                color={Colors.TextLight}
                size={18}
                style={{ margin: '0 0.375rem 2px 0' }}
              />
            }
          >
            Relevant
          </Button>
        }
      />

      <Button
        onClick={handleDenyRelevance}
        hollow
        iconStart={
          <FiX
            color={Colors.TextLight}
            size={16}
            strokeWidth="2.75"
            style={{ margin: '0 0.125rem 2px 0' }}
          />
        }
      >
        Not Relevant
      </Button>
    </Buttons>
  )
}

export default RelevancyInput
