import { Arrow, Content } from '@radix-ui/react-tooltip'
import styled, { keyframes } from 'styled-components'
import { Colors, FontSizes } from 'theme'

export const animation = keyframes`
from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const TooltipContent = styled(Content)`
  animation-name: ${animation};
  animation-duration: 250ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: white;
  border-radius: 2px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  color: ${Colors.Text};
  font-size: ${FontSizes.XSmall};
  line-height: 1;
  padding: 0.75em;
  user-select: none;
  will-change: transform, opacity;
`

export const TooltipArrow = styled(Arrow)`
  fill: white;
`
