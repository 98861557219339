import { Keys } from 'config'
import { log } from 'shared'
import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from '../../api.helpers'
import { CaseBusinessUnits } from 'types'

export const getCaseBusinessUnits = async (caseId: string): Promise<CaseBusinessUnits> => {
  log('🔍 API: getCaseBusinessUnits')

  const { data, error, status } = await apiClient
    .from<Keys.CaseBusinessUnits, Database['public']['Tables'][Keys.CaseBusinessUnits]>(
      Keys.CaseBusinessUnits
    )
    .select(
      `
      units(id, name)
    `
    )
    .eq('case', caseId)

  if (isBadResponseStatus(status)) {
    throw new Error(`getCaseBusinessUnits failed with status: ${status} and error: ${error}`)
  }

  return (
    data?.map(({ units }) => ({
      businessUnitId: Array.isArray(units) ? units?.[0]?.id : units?.id,
      businessUnitName: Array.isArray(units) ? units?.[0]?.name : units?.name
    })) ?? []
  )
}
