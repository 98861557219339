import { Keys } from 'config'
import { log as logger } from 'shared'

import { isBadResponseStatus } from 'api/api.helpers'
import { Database } from '../../api.types'
import apiClient from '../../apiClient'

type Props = {
  controversyId: string
  fingerprintId: string
  labelId: string
  start: number
  end: number
}

const deleteLabeling = async ({ controversyId, fingerprintId, labelId, start, end }: Props) => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user?.id

  logger('➕ API: deleteLabeling for online user', {
    onlineUserId,
    controversyId,
    fingerprintId,
    labelId,
    start,
    end
  })

  const { status } = await apiClient
    .from<Keys.Labeling, Database['public']['Tables'][Keys.Labeling]>(Keys.Labeling)
    .delete()
    .match({
      controversy: controversyId,
      creator: onlineUserId,
      label: labelId,
      fingerprint: fingerprintId,
      start,
      end
    })

  if (isBadResponseStatus(status)) {
    throw new Error(`deleteLabel responded with bad status code: ${status}`)
  }
}

export default deleteLabeling
