import { createContext } from 'react'

export type ShowToastProps = { description: string; title: string; type?: 'success' | 'error' }

const ToastContext = createContext<{
  showToast: (options: ShowToastProps) => void
}>({
  showToast: () => {}
})

export default ToastContext
