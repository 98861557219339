import { useQuery } from '@tanstack/react-query'
import { getControversies } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'

const useGetControversies = (options?: FetcherOptions) => {
  return {
    ...useQuery([Keys.Controversies], () => getControversies(), getDefaultFetcherOptions(options))
  }
}

export default useGetControversies
