import { Keys } from 'config'
import { createId, logError } from 'shared'
import { Note } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type CreateNote = {
  caseId: string
  note: Omit<Note, 'id' | 'createdAt'> & { public: boolean }
}

const createNote = async ({ caseId, note }: CreateNote) => {
  const session = await apiClient.auth.getSession()
  const userId = session.data.session!.user.id

  const { data, error } = await apiClient
    .from<Keys.Notes, Database['public']['Tables'][Keys.Notes]>(Keys.Notes)
    .insert({
      ...note,
      case: caseId,
      creator: userId,
      id: createId(),
      public: note.public ?? false
    })

  if (error) {
    logError('createNote failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}

export default createNote
