import { log } from 'shared'
import { Controversy, Range } from 'types'
import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'

type Options = {
  range: Range
}

const getControversies = async (options?: Options): Promise<Array<Controversy>> => {
  const { range } = options || {}
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id
  const userTeam = session.data.session?.user.user_metadata.team

  log('🔍 API: getControversies', { onlineUserId })

  let query = apiClient
    .rpc('get_open_user_controversies_with_fingerprints', {
      user_id: onlineUserId,
      team_id: userTeam
    })
    .select(
      `
    *
    `
    )

  if (range) {
    query = query.range(range.from, range.to)
  }

  const { data: controversiesData, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getControversies failed with status: ${status} and error: ${error}`)
  }

  const controversies = controversiesData?.map(
    ({
      abstract,
      date,
      document_type,
      id,
      fingerprint_ids,
      fingerprint_names,
      label_names,
      source,
      fulltext,
      title,
      url
    }) => {
      return {
        abstract,
        date,
        documentType: document_type,
        fingerprints:
          Array.isArray(fingerprint_ids) && fingerprint_ids[0]
            ? fingerprint_ids.map((fingerprint_id, i) => {
                return {
                  fingerprintId: fingerprint_id,
                  fingerprintName: fingerprint_names[i],
                  labelNames: label_names[i]
                }
              })
            : [],
        fulltext,
        id,
        source,
        title,
        url
      } as Controversy
    }
  )

  return controversies ?? []
}

export default getControversies
