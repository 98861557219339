import { Keys } from 'config'
import { log as logger } from 'shared'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from 'api/api.helpers'

const deleteFingerprint = async (fingerprintId: string) => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user?.id

  logger('API: deleteFingerprint', { onlineUserId, fingerprintId })

  const { status } = await apiClient
    .from<Keys.Fingerprints, Database['public']['Tables'][Keys.Fingerprints]>(Keys.Fingerprints)
    .delete()
    .eq('id', fingerprintId)

  if (isBadResponseStatus(status)) {
    throw new Error(`deleteFingerprint responded with bad status code: ${status}`)
  }
}

export default deleteFingerprint
