import { RiAddLine } from 'react-icons/ri'

import { Button, Layout, TabsContent, TabsList, TabsRoot } from 'components'

import { ActiveSources } from './ActiveSources'
import { AvailableSources } from './AvailableSources'
import { RequestedSources } from './RequestedSources'

const Sources = () => {
  return (
    <Layout
      componentEnd={
        <Button
          href="/sources/request"
          iconStart={<RiAddLine style={{ margin: '0 0.5rem 2px 0' }} />}
        >
          Request new source
        </Button>
      }
    >
      <TabsRoot defaultValue="active">
        <TabsList
          tabs={[
            { name: 'Active Sources', value: 'active' },
            { name: 'Available Sources', value: 'available' },
            { name: 'Requested Sources', value: 'requested' }
          ]}
        />
        <TabsContent value="active">
          <ActiveSources />
        </TabsContent>
        <TabsContent value="available">
          <AvailableSources />
        </TabsContent>
        <TabsContent value="requested">
          <RequestedSources />
        </TabsContent>
      </TabsRoot>
    </Layout>
  )
}

export default Sources
