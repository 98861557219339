import { log } from 'shared'
import { SearchRegulationsParams } from 'types'
import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'

type Options = {
  params: SearchRegulationsParams
}

export const getRegulationsCountBySearchTerm = async (options?: Options): Promise<number> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getRegulationsCountBySearchTerm', { onlineUserId })

  let query = apiClient.rpc('search_regulations', options?.params, { count: 'exact' })

  const { count, status, error } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(
      `getRegulationsCountBySearchTerm failed with status: ${status} and error: ${error}`
    )
  }

  return count ?? 0
}
