import * as Select from '@radix-ui/react-select'
import { RiCheckLine, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import styled, { css } from 'styled-components'
import { BorderRadius, Colors, ControlSizes, FontSizes } from 'theme'

const ITEM_HEIGHT = ControlSizes.Base

export const CheckIcon = styled(RiCheckLine)``

export const DownIcon = styled(RiArrowDownSLine)``

export const SelectContent = styled(Select.Content)`
  background-color: white;
  border-radius: ${BorderRadius.Controls};
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  overflow: hidden;
`

export const SelectGroup = styled(Select.Group)``

export const SelectIcon = styled(Select.Icon)``

export const SelectItem = styled(Select.Item)`
  align-items: center;
  border-radius: ${BorderRadius.Controls};
  color: ${Colors.Text};
  display: flex;
  font-size: ${FontSizes.BaseInput};
  height: ${ITEM_HEIGHT};
  line-height: 1;
  padding: 0 calc(${ITEM_HEIGHT} * 1.5) 0 ${ITEM_HEIGHT};
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${Colors.TextLight};
    pointer-events: none;
  }

  &[data-highlighted] {
    background-color: ${Colors.Primary};
    color: #fff;
    outline: none;
  }
`

export const SelectItemIndicator = styled(Select.ItemIndicator)`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: ${ITEM_HEIGHT};
`

export const SelectItemText = styled(Select.ItemText)``

export const SelectPortal = styled(Select.Portal)``

export const SelectRoot = styled(Select.Root)``

const sharedScrollButtonStyles = css`
  align-items: center;
  background-color: white;
  color: ${Colors.Text};
  cursor: default;
  display: flex;
  height: ${ITEM_HEIGHT};
  justify-content: center;
`

export const SelectScrollDownButton = styled(Select.ScrollDownButton)`
  ${sharedScrollButtonStyles};
`

export const SelectScrollUpButton = styled(Select.ScrollUpButton)`
  ${sharedScrollButtonStyles};
`

export const SelectTrigger = styled(Select.Trigger)`
  align-items: center;
  background-color: white;
  border: 1px solid ${Colors.Divider};
  border-radius: ${BorderRadius.Controls};
  color: ${Colors.Text};
  display: inline-flex;
  font-size: ${FontSizes.BaseInput};
  justify-content: center;
  gap: 0.375rem;
  height: ${ITEM_HEIGHT};
  line-height: 1;
  padding: 0 1.5rem;

  &:hover {
    border-color: ${Colors.Text};
  }

  &[data-placeholder] {
    color: ${Colors.TextLight};
  }
`

export const SelectValue = styled(Select.Value)``

export const SelectViewport = styled(Select.Viewport)`
  padding: 0.375rem;
`

export const UpIcon = styled(RiArrowUpSLine)``
