import { Keys } from 'config'
import { log, logError } from 'shared'
import { Case } from 'types'

import apiClient from '../../apiClient'
import { isBadResponseStatus } from '../../api.helpers'
import { Database } from '../../api.types'

const getCase = async (caseId?: string): Promise<Case | null> => {
  if (!caseId) {
    return null
  }

  log('🔍 API: getCase')

  const {
    data,
    error,
    status: responseStatus
  } = await apiClient
    .from<Keys.Cases, Database['public']['Tables'][Keys.Cases]>(Keys.Cases)
    .select()
    .eq('id', caseId)
    .single()

  if (isBadResponseStatus(responseStatus)) {
    throw new Error(`getCase failed with status: ${responseStatus} and error: ${error}`)
  }

  if (!data) {
    return null
  }

  const {
    controversy,
    created_at,
    id,
    links: linksString,
    name,
    reference,
    status,
    updated_at
  } = data

  const links: Array<{ name: string; url: string }> = []

  try {
    const parsedLinks = JSON.parse(linksString ?? '')
    if (parsedLinks && Array.isArray(parsedLinks)) {
      parsedLinks.forEach(link => {
        if (link.name && link.url) {
          links.push({ name: link.name, url: link.url })
        }
      })
    }
  } catch (error) {
    logError(error)
  }

  return {
    controversy,
    createdAt: created_at,
    id,
    links,
    name,
    reference,
    status,
    updatedAt: updated_at ?? created_at
  } as Case
}

export default getCase
