import { useQuery } from '@tanstack/react-query'
import { getNotes, GetNotes } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetPrivateNotes = (filters: GetNotes, options?: FetcherOptions) => {
  return useQuery(
    [Keys.Notes, filters.caseId, 'private'],
    () => getNotes(filters),
    getDefaultFetcherOptions(options)
  )
}

export default useGetPrivateNotes
