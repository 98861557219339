import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteLabeling } from 'api'
import { Keys } from 'config'
import { useToast } from 'stores'
import { Labeling } from 'types'

type Props = {
  controversyId: string
  labelId: string
  fingerprintId: string
  start: number
  end: number
}

const useDeleteLabeling = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(
    ({ controversyId, labelId, fingerprintId, start, end }: Props) =>
      deleteLabeling({ controversyId, labelId, fingerprintId, start, end }),
    {
      onMutate: async ({ controversyId, labelId, fingerprintId, start, end }: Props) => {
        const key = [Keys.Labeling, controversyId]

        await queryClient.cancelQueries(key)

        const previousLabelings = queryClient.getQueryData<Labeling[]>(key) ?? []

        queryClient.setQueryData(
          key,
          (previousLabelings ?? []).filter(
            labeling =>
              controversyId !== labeling.controversyId ||
              fingerprintId !== labeling.fingerprintId ||
              labelId !== labeling.labelId ||
              start !== labeling.start ||
              end !== labeling.end
          )
        )

        return { previousLabelings }
      },
      onError: (_, { controversyId }, context) => {
        queryClient.setQueryData([Keys.Labeling, controversyId], context?.previousLabelings ?? [])
        showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
      },
      onSettled: (_, __, { controversyId }) => {
        queryClient.invalidateQueries([Keys.Labeling, controversyId])
        queryClient.invalidateQueries([Keys.Controversies])

        showToast({ description: 'Labeling deleted successfully', title: 'Success ✅' })
      }
    }
  )
}

export default useDeleteLabeling
