import styled from 'styled-components'
import { RiPriceTag3Line, RiBilibiliLine } from 'react-icons/ri'
import { Colors, FontSizes } from 'theme'
import { Color } from 'types'

export const DefaultLabelIcon = styled(RiPriceTag3Line)`
  margin-bottom: 1px;
`

export const AiLabelIcon = styled(RiBilibiliLine)`
  margin-bottom: 1px;
`

export const Text = styled.span`
  color: ${Colors.Text};
  font-size: ${FontSizes.XSmall};
  font-weight: 600;
`

export const Wrapper = styled.button<{ $active?: boolean; $color: Color; $inactive?: boolean }>`
  align-items: center;
  background-color: ${({ $active, $color }) => ($active ? $color.active : $color.passive)};
  border-radius: 1rem;
  display: flex;
  flex-grow: 0;
  gap: 0.375rem;
  height: 2rem;
  justify-content: center;
  opacity: ${({ $inactive }) => ($inactive ? 0.4 : 1)};
  padding: 0 1rem 0 0.875rem;

  ${DefaultLabelIcon}, ${AiLabelIcon} {
    fill: ${({ $active, $color }) => ($active ? '#fff' : $color.active)};
  }

  ${Text} {
    color: ${({ $active, $color }) => ($active ? '#fff' : $color.active)};
  }
`

export const CloseButton = styled.button`
  display: flex;
`
