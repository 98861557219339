import { TabsContent, TabsList, TabsRoot } from 'components'
import { FC } from 'react'
import { PrivateNotes, PublicNotes } from './components'
import { TabContentWrapper, Wrapper } from './Notes.styles'

const Notes: FC<{ caseId: string }> = ({ caseId }) => {
  return (
    <Wrapper>
      <TabsRoot defaultValue="public">
        <TabsList
          tabs={[
            { name: 'Public', value: 'public' },
            { name: 'Private', value: 'private' }
          ]}
        />
        <TabsContent value="public">
          <TabContentWrapper>
            <PublicNotes caseId={caseId} />
          </TabContentWrapper>
        </TabsContent>
        <TabsContent value="private">
          <TabContentWrapper>
            <PrivateNotes caseId={caseId} />
          </TabContentWrapper>
        </TabsContent>
      </TabsRoot>
    </Wrapper>
  )
}

export default Notes
