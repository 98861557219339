import { Keys } from 'config'
import { createId, logError } from 'shared'
import { ControversyTable } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type CreateControversy = {
  controversy: Omit<ControversyTable, 'id' | 'created_at' | 'updated_at' | 'team'>
}

export const createControversy = async ({ controversy }: CreateControversy) => {
  const session = await apiClient.auth.getSession()
  const userTeam = session.data.session?.user.user_metadata.team

  const { data, error } = await apiClient
    .from<Keys.Controversies, Database['public']['Tables'][Keys.Controversies]>(Keys.Controversies)
    .insert({
      ...controversy,
      id: createId(),
      team: userTeam
    })

  if (error) {
    logError('createControversy failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}
