import styled from 'styled-components'
import { BorderRadius, Colors, FontSizes } from 'theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

export const Label = styled.label<{ $inverted?: boolean }>`
  color: ${Colors.Text};
  font-size: ${FontSizes.XSmall};
  font-weight: 600;
`

export const Textarea = styled.textarea`
  background: #fff;
  border: 1px solid ${Colors.InputBorder};
  color: ${Colors.Text};
  border-radius: ${BorderRadius.Controls};
  font-size: ${FontSizes.BaseInput};
  outline: none;
  padding: 0.75rem 1rem;
  width: 100%;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${Colors.Placeholder};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${Colors.Placeholder};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${Colors.Placeholder};
  }

  &:focus-within {
    border-color: ${Colors.Text};
  }
`
