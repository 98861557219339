import { Close, Portal, Overlay, Content, Root, Trigger } from '@radix-ui/react-dialog'
import { FC, ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { BorderRadius, Colors } from 'theme'

const Dialog: FC<{
  contentComponent: ReactNode
  footerComponent?: ReactNode
  onOpenChange?: (open: boolean) => void
  open?: boolean
  triggerComponent?: ReactNode
}> = ({
  contentComponent: ContentComponent,
  footerComponent: FooterComponent,
  onOpenChange,
  open,
  triggerComponent: TriggerComponent
}) => {
  return (
    <Root onOpenChange={onOpenChange} open={open}>
      {TriggerComponent && <Trigger>{TriggerComponent}</Trigger>}
      <Portal>
        <DialogOverlay>
          <DialogContent>
            <Main>{ContentComponent}</Main>
            {FooterComponent && <Footer>{FooterComponent}</Footer>}
          </DialogContent>
        </DialogOverlay>
      </Portal>
    </Root>
  )
}

export default Dialog

export { Close as DialogClose }

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const DialogOverlay = styled(Overlay)`
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  position: fixed;
`

export const DialogContent = styled(Content)`
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: white;
  border-radius: ${BorderRadius.Tiles};
  /* box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px; */
  left: 50%;
  max-height: 85vh;
  max-width: 24rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
`

export const Main = styled.div`
  padding: 1.5rem;
  width: 100%;
`

export const Footer = styled.div`
  background-color: ${Colors.Base};
  border-bottom-left-radius: ${BorderRadius.Tiles};
  border-bottom-right-radius: ${BorderRadius.Tiles};
  padding: 0.75rem 1.5rem;
  width: 100%;
`
