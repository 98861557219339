import { Button, Divider, Layout, Loader, Row } from 'components'

import {
  StyledRoot,
  StyledMain,
  StyledSidebar,
  StyledSidebarCard,
  ClearAll,
  StyledSearchWrapper,
  StyledTextInput,
  Count,
  ListRoot,
  List
} from './Regulations.styles'
import { RiSearchLine } from 'react-icons/ri'
import {
  useGetRegulationsBySearchTerm,
  useGetRegulationsCount,
  useGetRegulationsCountBySearchTerm
} from 'data-fetcher/regulations'
import { Fragment, useEffect, useState } from 'react'
import { useToast } from 'stores'

import { ActiveFilters, Filters, Pagination } from './components'

import { DocExcerpt } from './components'

const Regulations = () => {
  const [searchInput, setSearchInput] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({})

  const regulationParams = {
    search_titlefulltext: searchTerm,
    filter_authority: activeFilters.authority?.length ? activeFilters.authority : null,
    filter_date_end: null,
    filter_date_start: null,
    filter_document_type: activeFilters.document_type?.length ? activeFilters.document_type : null,
    filter_jurisdiction: activeFilters.jurisdiction?.length ? activeFilters.jurisdiction : null,
    filter_source: activeFilters.source?.length ? activeFilters.source : null,
    filter_iscontroversy: null
  }

  const { data: countBySearchTerm } = useGetRegulationsCountBySearchTerm(regulationParams)
  const { data: countAll } = useGetRegulationsCount(regulationParams)

  const { data, isFetching, isError } = useGetRegulationsBySearchTerm(regulationParams, page)

  const { showToast } = useToast()

  const handleSearchButtonClick = () => {
    setSearchTerm(searchInput)
  }

  useEffect(() => {
    if (isError && !isFetching) {
      showToast({
        title: 'Error',
        description: 'An error occurred loading new regulations.',
        type: 'error'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isFetching])

  const clearAllFilters = () => {
    setActiveFilters({
      jurisdiction: [],
      source: [],
      authority: []
    })
  }

  return (
    <Layout>
      <StyledRoot>
        <StyledSidebar>
          <div>
            <StyledSidebarCard
              componentRight={
                <ClearAll
                  onClick={() => clearAllFilters()}
                  active={Boolean(Object.values(activeFilters).flat().length)}
                >
                  Reset all
                </ClearAll>
              }
              title="Filters"
            >
              <Filters
                searchTerm={searchTerm}
                activeFilters={activeFilters}
                onActiveFiltersChange={setActiveFilters}
                onFilterClearClicked={filter =>
                  setActiveFilters(oldFilters => ({ ...oldFilters, [filter]: [] }))
                }
              />
            </StyledSidebarCard>
          </div>
        </StyledSidebar>
        <StyledMain>
          <StyledSearchWrapper>
            <Row alignItems="flex-end" gap="0.5rem">
              <StyledTextInput
                onChange={setSearchInput}
                onSubmit={handleSearchButtonClick}
                value={searchInput}
                placeholder="Type your search query ..."
              />
              <Button
                onClick={() => handleSearchButtonClick()}
                iconStart={<RiSearchLine style={{ margin: '0 0.5rem 0 0' }} />}
              >
                Search
              </Button>
            </Row>
          </StyledSearchWrapper>
          {isFetching && !isError && <Loader />}

          {data && (
            <>
              <Count>{searchTerm === '' ? countAll : countBySearchTerm} Results</Count>
              <ListRoot>
                <List>
                  {data.map((regulation, index) => {
                    const href = `/regulations/${regulation.id}`

                    return (
                      <Fragment key={`regulation-${regulation.id}`}>
                        {index !== 0 && <Divider />}
                        <DocExcerpt data={{ ...regulation, href }} />
                      </Fragment>
                    )
                  })}
                </List>
                <Pagination
                  currentPage={page}
                  entryCount={(searchTerm === '' ? countAll : countBySearchTerm) || 0}
                  onClick={page => setPage(page)}
                />
              </ListRoot>
            </>
          )}
        </StyledMain>
      </StyledRoot>
    </Layout>
  )
}

export default Regulations
