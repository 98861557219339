import { FC } from 'react'
import { RiCloseLine } from 'react-icons/ri'
import { Color } from 'types'
import { AiLabelIcon, CloseButton, DefaultLabelIcon, Text, Wrapper } from './Label.styles'

export type LabelProps = {
  active?: boolean
  color: Color
  inactive?: boolean
  id: string
  isAiLabel?: boolean
  name: string
  onClick?: () => void
  onClose?: () => void
}

const Label: FC<LabelProps> = ({ active, color, inactive, isAiLabel, name, onClick, onClose }) => {
  return (
    <Wrapper
      $active={active}
      $inactive={inactive}
      as={onClick ? 'button' : 'div'}
      $color={color}
      onClick={onClick}
    >
      {isAiLabel ? (
        <AiLabelIcon color={color.active} size={14} />
      ) : (
        <DefaultLabelIcon color={color.active} size={14} />
      )}
      <Text>{name}</Text>
      {Boolean(onClose) && (
        <CloseButton onClick={onClose}>
          <RiCloseLine color={color.active} size={16} style={{ marginBottom: '1px' }} />
        </CloseButton>
      )}
    </Wrapper>
  )
}

export default Label
