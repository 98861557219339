import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createDocuments } from 'api'
import { Keys } from 'config'

const useCreateDocuments = () => {
  const queryClient = useQueryClient()

  return useMutation(createDocuments, {
    onSettled: (_, __, { caseId, visibility }) => {
      queryClient.invalidateQueries([Keys.Documents, caseId, visibility])
    }
  })
}

export default useCreateDocuments
