import { FC, useState } from 'react'
import { Button, TextInput } from 'components'
import { NoDataLabel } from '../../Case.styles'
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTitle,
  Heading,
  TextInputs
} from '../../Case.styles'
import { Link, LinkIcon, LinkLabel, Wrapper } from './Links.styles'
import { useUpdateCase } from 'data-fetcher'

const Links: FC<{
  additionalLinks?: Array<{ name: string; url: string }>
  caseId: string
  source: { name?: string; url?: string }
}> = ({ additionalLinks = [], caseId, source }) => {
  const { name, url } = source

  const [linkName, setLinkName] = useState('')
  const [linkUrl, setLinkUrl] = useState('')

  const linkInputCompleted = Boolean(linkName && linkUrl)

  const { mutate: updateCase } = useUpdateCase()

  const handleSaveLink = () => {
    if (linkInputCompleted) {
      updateCase({
        caseId,
        updatedCase: { links: [...additionalLinks, { name: linkName, url: linkUrl }] }
      })

      setLinkName('')
      setLinkUrl('')
    }
  }

  return (
    <Wrapper>
      <Heading>Source</Heading>
      {name && url ? (
        <Link href={url}>
          <LinkIcon />
          <LinkLabel>{name}</LinkLabel>
        </Link>
      ) : (
        <NoDataLabel>No source link available.</NoDataLabel>
      )}

      <Heading>Additional Links</Heading>
      {additionalLinks?.length ? (
        additionalLinks.map(({ name, url }) => (
          <Link href={url} key={`additional-link-${name}`} target="_blank">
            <LinkIcon />
            <LinkLabel>{name}</LinkLabel>
          </Link>
        ))
      ) : (
        <>
          <br />
          <NoDataLabel>No additional links.</NoDataLabel>
        </>
      )}

      <AccordionRoot collapsible type="single">
        <AccordionItem value="content">
          <AccordionHeader>
            <AccordionTitle>Add Link</AccordionTitle>
          </AccordionHeader>
          <AccordionContent>
            <TextInputs>
              <TextInput
                onChange={input => setLinkName(input)}
                placeholder="Name"
                value={linkName}
              />
              <TextInput onChange={input => setLinkUrl(input)} placeholder="URL" value={linkUrl} />
            </TextInputs>
            <Button onClick={handleSaveLink}>Save</Button>
          </AccordionContent>
        </AccordionItem>
      </AccordionRoot>
    </Wrapper>
  )
}

export default Links
