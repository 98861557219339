import { useGetPaginatedCases } from 'data-fetcher'
import { Fragment } from 'react'
import { formatDate } from 'shared'
import { CaseStatus } from 'types'
import {
  Card,
  CasesLoadMore,
  ContentRow,
  Loader,
  NoData,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow
} from '../Cases.styles'

const CasesOpen = () => {
  const {
    data: cases,
    isFetching,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useGetPaginatedCases({
    caseStatuses: [CaseStatus.InProgress, CaseStatus.New, CaseStatus.Review],
    options: { keepPreviousData: true }
  })

  if (isLoading) {
    return <Loader />
  }
  if (!Array.isArray(cases?.pages) || !cases?.pages[0]?.length) {
    return <NoData copy="No data!" icon="data" />
  }

  return (
    <>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading>Name</TableHeading>
              <TableHeading>Reference</TableHeading>
              <TableHeading>Created At</TableHeading>
              <TableHeading>Last Update</TableHeading>
              <TableHeading>Status</TableHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {cases?.pages?.map((page, index) => (
                <Fragment key={index}>
                  {page.map(({ createdAt, id, name, reference, status, updatedAt }) => (
                    <ContentRow key={`open-case-${id}`} to={`/cases/${id}`}>
                      <TableData>{name ?? '-'}</TableData>
                      <TableData>{reference ?? '-'}</TableData>
                      <TableData>{formatDate(createdAt)}</TableData>
                      <TableData>{formatDate(updatedAt)}</TableData>
                      <TableData>{status}</TableData>
                    </ContentRow>
                  ))}
                </Fragment>
              ))}
            </>
          </TableBody>
        </Table>
      </Card>
      {hasNextPage && <CasesLoadMore isLoading={isFetching} onLoadMore={fetchNextPage} />}
    </>
  )
}

export default CasesOpen
