import { FC, useState } from 'react'

import { Button } from 'components'
import { useCreateNote, useGetPrivateNotes } from 'data-fetcher'

import { Note, NoteDate, NotesList, NoteText, TextArea } from './shared'

import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTitle,
  NoDataLabel
} from '../../../Case.styles'
import { formatDate } from 'shared'

const PrivateNotes: FC<{ caseId: string }> = ({ caseId }) => {
  const [input, setInput] = useState('')

  const { mutate: createNote } = useCreateNote()
  const { data: notes } = useGetPrivateNotes({ caseId, visibility: 'private' })

  const handleCreateNote = () => {
    createNote({ caseId, note: { public: false, text: input } })
    setInput('')
  }

  return (
    <>
      {notes?.length ? (
        <NotesList>
          {notes?.map(({ createdAt, id, text }) => (
            <Note key={`note-${id}`}>
              <NoteDate>{formatDate(createdAt)}</NoteDate>
              <NoteText>{text}</NoteText>
            </Note>
          ))}
        </NotesList>
      ) : (
        <NoDataLabel>No private notes.</NoDataLabel>
      )}

      <AccordionRoot collapsible type="single">
        <AccordionItem value="content">
          <AccordionHeader>
            <AccordionTitle>Add private note</AccordionTitle>
          </AccordionHeader>
          <AccordionContent>
            <TextArea
              onChange={e => setInput(e.target.value)}
              placeholder="Add your private note"
              rows={3}
              value={input}
            />
            <Button disabled={!input} onClick={handleCreateNote}>
              Save
            </Button>
          </AccordionContent>
        </AccordionItem>
      </AccordionRoot>
    </>
  )
}

export default PrivateNotes
