import { FC } from 'react'
import { TextInput as Input, Label, Wrapper } from './TextInput.styles'

const TextInput: FC<{
  className?: string
  inverted?: boolean
  label?: string
  onChange?: (input: string) => void
  onSubmit?: () => void
  onClick?: () => void
  placeholder?: string
  renderAs?: string
  size?: 'base' | 'large'
  type?: string
  value?: string
}> = ({
  className,
  inverted,
  label,
  onChange,
  onClick,
  renderAs = 'input',
  onSubmit,
  size,
  value,
  ...props
}) => (
  <Wrapper className={className}>
    {label && <Label $inverted={inverted}>{label}</Label>}
    <Input
      $inverted={inverted}
      onChange={e => onChange?.(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onSubmit?.()
        }
      }}
      onClick={() => onClick?.()}
      $size={size || 'base'}
      value={value ?? ''}
      {...props}
    />
  </Wrapper>
)

export default TextInput
