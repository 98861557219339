import styled from 'styled-components'
import { BorderRadius, Colors, ControlSizes, FontSizes } from 'theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

export const Label = styled.label<{ $inverted?: boolean }>`
  color: ${({ $inverted }) => ($inverted ? 'rgba(255, 255, 255, 0.5)' : Colors.Text)};
  font-size: ${FontSizes.XSmall};
  font-weight: 600;
`

export const TextInput = styled.input<{ $inverted?: boolean; $size: 'base' | 'large' }>`
  background: ${({ $inverted }) => ($inverted ? 'rgba(255, 255, 255, 0.09)' : '#fff')};
  border: ${({ $inverted }) => ($inverted ? 'none' : `1px solid ${Colors.InputBorder}`)};
  color: ${({ $inverted }) => ($inverted ? '#fff' : Colors.Text)};
  border-radius: ${BorderRadius.Controls};
  font-size: ${({ $size }) => ($size === 'large' ? FontSizes.LargeInput : FontSizes.BaseInput)};
  height: ${({ $size }) => ($size === 'large' ? ControlSizes.Large : ControlSizes.Base)};
  padding: 0 1rem;
  width: 100%;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ $inverted }) => ($inverted ? '#8487a2' : Colors.Placeholder)};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ $inverted }) => ($inverted ? '#8487a2' : Colors.Placeholder)};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ $inverted }) => ($inverted ? '#8487a2' : Colors.Placeholder)};
  }

  &:focus-within {
    border-color: ${({ $inverted }) => ($inverted ? 'transparent' : Colors.Text)};
  }
`
