import { useQuery } from '@tanstack/react-query'
import { getLabeling } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetLabeling = ({
  controversyId,
  includeAiLabeling,
  options,
  userId
}: {
  controversyId: string
  includeAiLabeling?: boolean
  options?: FetcherOptions
  userId?: string
}) => {
  return useQuery(
    [Keys.Labeling, controversyId, userId ?? null, includeAiLabeling ?? false],
    () => getLabeling({ controversyId, includeAiLabeling, userId }),
    getDefaultFetcherOptions(options)
  )
}

export default useGetLabeling
