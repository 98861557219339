import { Card, TabsList as BaseTabsList } from 'components'
import { GoChevronLeft, GoChevronRight } from 'react-icons/go'
import { RiFingerprintLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Colors, FontSizes } from 'theme'

const GAP = '1.5rem'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Nav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  width: 100%;
`

export const NavItemLabel = styled.span`
  color: ${Colors.Text};
  font-size: ${FontSizes.XSmall};
  font-weight: 600;
`

const sharedIconStyles = css`
  color: ${Colors.Text};
  height: 1rem;
  width: 1rem;
`

export const NavItemIconPrev = styled(GoChevronLeft)`
  ${sharedIconStyles};
`

export const NavItemIconNext = styled(GoChevronRight)`
  ${sharedIconStyles};
`

export const NavItem = styled(Link)<{ $active?: boolean }>`
  align-items: center;
  display: flex;
  gap: 0.25rem;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  pointer-events: ${({ $active }) => ($active ? 'all' : 'none')};

  &:hover {
    ${NavItemLabel}, ${NavItemIconPrev}, ${NavItemIconNext} {
      color: ${Colors.Primary};
    }
    ${NavItemLabel} {
      text-decoration: underline;
    }
  }
`

export const Grid = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 28rem;
  grid-gap: ${GAP};
`

const sharedColumnStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${GAP};
`

export const MainColumn = styled.div`
  ${sharedColumnStyles};
`

export const SideColumn = styled.div`
  ${sharedColumnStyles};
  height: 100%;
`

export const StickyCard = styled(Card)`
  position: sticky;
  top: 0;
`

export const AddLabelsIcon = styled(RiFingerprintLine)`
  color: ${Colors.Text};
  height: 1rem;
  width: 1rem;
`

export const TabsList = styled(BaseTabsList)`
  margin-bottom: 2rem;
`