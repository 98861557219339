import { FC, useEffect, useRef, useState } from 'react'

import { Button } from 'components'
import { useCreateDocuments, useGetPrivateDocuments } from 'data-fetcher'
import { useAuth, useToast } from 'stores'
import { logError } from 'shared'

import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTitle,
  NoDataLabel
} from '../../../Case.styles'

import {
  Document,
  DocumentName,
  DocumentsList,
  DownloadButton,
  DownloadButtonIcon,
  DownloadButtonLabel,
  FileUpload,
  HiddenDownloadLink,
  uploadFiles
} from './shared'
import { apiClient } from 'api'

const PrivateDocuments: FC<{ caseId: string }> = ({ caseId }) => {
  const [downloadName, setDownloadName] = useState('')
  const [downloadUrl, setDownloadUrl] = useState('')
  const [files, setFiles] = useState<Array<File> | undefined>()
  const [isUploading, setIsUploading] = useState(false)

  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null)

  const { userId } = useAuth()

  const { mutateAsync: createDocuments } = useCreateDocuments()
  const { data: documents } = useGetPrivateDocuments({ caseId, visibility: 'private' })

  const { showToast } = useToast()

  useEffect(() => {
    if (downloadName && downloadUrl) {
      downloadLinkRef.current?.click()

      URL.revokeObjectURL(downloadUrl)
      setDownloadName('')
      setDownloadUrl('')
    }
  }, [downloadName, downloadUrl])

  const handleDocumentsUpload = (uploadedFiles?: Array<File>) => {
    setFiles(uploadedFiles)
  }

  const handleDownloadFile = async ({ name, url }: { name: string; url: string }) => {
    const { data } = await apiClient.storage.from('documents').download(url)

    if (data) {
      const objectUrl = URL.createObjectURL(new Blob([data]))
      setDownloadName(name)
      setDownloadUrl(objectUrl)
    }
  }

  const saveUpload = async () => {
    if (files?.length) {
      try {
        setIsUploading(true)

        await uploadFiles({ files, userId, visibility: 'private' })

        await createDocuments({
          caseId,
          documents: files.map(({ name }) => ({ name, url: `${userId}/${name}` })),
          visibility: 'private'
        })
      } catch (error) {
        logError(error)
        showToast({
          description: 'An error ocurred. Please try again.',
          title: 'Error',
          type: 'error'
        })
      }

      setIsUploading(false)
      setFiles(undefined)
    }
  }

  return (
    <>
      {documents?.length ? (
        <DocumentsList>
          {documents?.map(({ id, name, url }) => (
            <Document key={`private-document-${id}`}>
              <DownloadButton onClick={() => handleDownloadFile({ name, url })}>
                <DownloadButtonIcon />
                <DownloadButtonLabel>Download</DownloadButtonLabel>
              </DownloadButton>
              <DocumentName>{name}</DocumentName>
            </Document>
          ))}
        </DocumentsList>
      ) : (
        <NoDataLabel>No private documents.</NoDataLabel>
      )}

      <AccordionRoot collapsible type="single">
        <AccordionItem value="content">
          <AccordionHeader>
            <AccordionTitle>Add private document</AccordionTitle>
          </AccordionHeader>
          <AccordionContent>
            <FileUpload
              loading={isUploading}
              onFileDrop={handleDocumentsUpload}
              uploadedFilesNames={files?.map(({ name }) => name)}
            />
            <Button disabled={!files || isUploading} onClick={saveUpload}>
              Save
            </Button>
          </AccordionContent>
        </AccordionItem>
      </AccordionRoot>

      <HiddenDownloadLink download={downloadName} href={downloadUrl} ref={downloadLinkRef} />
    </>
  )
}

export default PrivateDocuments
