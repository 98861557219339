import styled from 'styled-components'
import { Colors, FontSizes } from 'theme'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1.25rem;
`

export const Label = styled.span`
  color: ${Colors.TextLight};
  font-size: ${FontSizes.Small};
`
