import { useMutation } from '@tanstack/react-query'
import { createControversy } from 'api'

import { logError } from 'shared'
import { useToast } from 'stores'

export const useCreateControversy = () => {
  const { showToast } = useToast()

  return useMutation(createControversy, {
    onError: error => {
      logError('createControversy failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    }
  })
}
