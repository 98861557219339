import { Keys } from 'config'
import { logError } from 'shared'
import { Case } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type UpdateCase = {
  caseId: string
  updatedCase: Partial<Case>
}

const updateCase = async ({ caseId, updatedCase }: UpdateCase) => {
  const { links } = updatedCase

  const { data, error } = await apiClient
    .from<Keys.Cases, Database['public']['Tables'][Keys.Cases]>(Keys.Cases)
    .update({
      ...updatedCase,
      links: links ? JSON.stringify(links) : undefined
    })
    .eq('id', caseId)

  if (error) {
    logError('updateCase failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}

export default updateCase
