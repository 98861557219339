import { Keys } from 'config'
import { createId, logError } from 'shared'
import { Document } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type CreateDocuments = {
  caseId: string
  documents: Array<Omit<Document, 'id' | 'createdAt'>>
  visibility: 'private' | 'public'
}

const createDocuments = async ({ caseId, documents, visibility }: CreateDocuments) => {
  const session = await apiClient.auth.getSession()
  const userId = session.data.session!.user.id

  const { data, error } = await apiClient
    .from<Keys.Documents, Database['public']['Tables'][Keys.Documents]>(Keys.Documents)
    .insert(
      documents.map(document => ({
        ...document,
        case: caseId,
        creator: userId,
        id: createId(),
        public: visibility === 'public'
      }))
    )

  if (error) {
    logError('createDocuments failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}

export default createDocuments
