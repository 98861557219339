import { useGetSources, useRequestExistingSource } from 'data-fetcher'

import {
  Card,
  InlineButton,
  Link,
  Loader,
  NoData,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow
} from './Sources.styles'

export const AvailableSources = () => {
  const { data: sources, isLoading } = useGetSources({ status: 'disabled' })
  const { mutate: requestExistingSource } = useRequestExistingSource()

  return isLoading ? (
    <Loader />
  ) : !sources?.length ? (
    <NoData copy="No data!" icon="data" />
  ) : (
    <>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading>Identifier</TableHeading>
              <TableHeading>Name</TableHeading>
              <TableHeading>Language</TableHeading>
              <TableHeading>Description</TableHeading>
              <TableHeading>Thematic Category</TableHeading>
              <TableHeading>Geographical Scope</TableHeading>
              <TableHeading>Source Type</TableHeading>
              <TableHeading></TableHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            {sources.map(
              ({
                category,
                description,
                identifier,
                language,
                link,
                id,
                name,
                scope,
                sourceType
              }) => (
                <TableRow key={`source-${id}`}>
                  <TableData>{identifier ?? '-'}</TableData>
                  <TableData>
                    {name ? (
                      <Link href={link} rel="noopener noreferrer" target="_blank">
                        {name}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableData>
                  <TableData>{language ?? '-'}</TableData>
                  <TableData>{description ?? '-'}</TableData>
                  <TableData>{category ?? '-'}</TableData>
                  <TableData>{scope ?? '-'}</TableData>
                  <TableData>{sourceType ?? '-'}</TableData>
                  <TableData>
                    {
                      <InlineButton onClick={() => requestExistingSource({ sourceId: id })}>
                        Request
                      </InlineButton>
                    }
                  </TableData>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}
