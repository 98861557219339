import React, { FC } from 'react'
import { Root, StyledPagination, StyledPaginationItem } from './Pagination.styles'
import { Button } from 'components'

type PaginationProps = {
  currentPage: number
  entryCount: number
  onClick: (page: number) => void
}

export const Pagination: FC<PaginationProps> = ({ currentPage, entryCount, onClick }) => {
  const pageCount = Math.ceil(entryCount / 10) // Calculate total pages

  const renderPaginationItems = () => {
    const items: React.ReactElement[] = []

    // Display first page
    items.push(
      <StyledPaginationItem key={1} active={currentPage === 1} onClick={() => onClick(1)}>
        1
      </StyledPaginationItem>
    )

    // Display dots if required before current page
    if (currentPage > 4) {
      items.push(<span key="dotsBefore">...</span>)
    }

    // Display up to 2 previous page numbers from current page
    for (let i = Math.max(currentPage - 2, 2); i < currentPage; i++) {
      items.push(
        <StyledPaginationItem key={i} active={false} onClick={() => onClick(i)}>
          {i}
        </StyledPaginationItem>
      )
    }

    // Display current page
    if (currentPage !== 1 && currentPage !== pageCount) {
      items.push(
        <StyledPaginationItem key={currentPage} active={true} onClick={() => onClick(currentPage)}>
          {currentPage}
        </StyledPaginationItem>
      )
    }

    // Display up to 2 next page numbers from current page
    for (let i = currentPage + 1; i <= Math.min(currentPage + 2, pageCount - 1); i++) {
      items.push(
        <StyledPaginationItem key={i} active={false} onClick={() => onClick(i)}>
          {i}
        </StyledPaginationItem>
      )
    }

    // Display dots if required after current page
    if (currentPage < pageCount - 3) {
      items.push(<span key="dotsAfter">...</span>)
    }

    // Display last page
    if (pageCount > 1) {
      items.push(
        <StyledPaginationItem
          key={pageCount}
          active={currentPage === pageCount}
          onClick={() => onClick(pageCount)}
        >
          {pageCount}
        </StyledPaginationItem>
      )
    }

    return items
  }

  return (
    <Root>
      {currentPage !== 1 && <Button onClick={() => onClick(currentPage - 1)}>Previous</Button>}
      <StyledPagination>{renderPaginationItems()}</StyledPagination>
      {currentPage !== pageCount && <Button onClick={() => onClick(currentPage + 1)}>Next</Button>}
    </Root>
  )
}
