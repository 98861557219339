import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://yabtukgiisyahiehdoyg.supabase.co'
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlhYnR1a2dpaXN5YWhpZWhkb3lnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzI3NzY1MTcsImV4cCI6MTk4ODM1MjUxN30.Ag2GMLPuuS7mo2w472z2bLj05A9a7luNp-1DKQA8vO4'

// Exposing keys is fine due to Postgres row level security
const apiClient = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false
  }
})

export default apiClient
