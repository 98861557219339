import styled from 'styled-components'
import { RiDownloadLine } from 'react-icons/ri'
import { FileUpload as BaseFileUpload } from 'components'
import { Colors, FontSizes } from 'theme'

export const DocumentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Document = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.Divider};
  display: flex;
  padding-bottom: 1rem;
`

export const DocumentName = styled.span`
  font-size: ${FontSizes.Base};
  line-height: 1.4;
`

export const DownloadButton = styled.button`
  align-items: center;
  display: flex;
  gap: 0.125rem;
  margin-right: 1rem;
`

export const DownloadButtonLabel = styled.span`
  color: ${Colors.Primary};
  font-size: ${FontSizes.XSmall};
  font-weight: 600;
`

export const DownloadButtonIcon = styled(RiDownloadLine)`
  color: ${Colors.Primary};
  height: 0.875rem;
  width: 0.875rem;
`

export const TextArea = styled.textarea`
  border-color: ${Colors.InputBorder};
  line-height: 1.4;
  margin: 1rem 0;
  outline: none;
  padding: 0.75rem 1rem;
  width: 100%;

  ::placeholder {
    color: ${Colors.Placeholder};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${Colors.Placeholder};
  }

  ::-ms-input-placeholder {
    color: ${Colors.Placeholder};
  }

  &:focus-within {
    border-color: ${Colors.Text};
  }
`

export const FileUpload = styled(BaseFileUpload)`
  margin: 1.5rem 0;
`

export const HiddenDownloadLink = styled.a`
  pointer-events: none;
  visibility: hidden;
`
