import { useNavigate } from 'react-router-dom'
import { EmptyNote, Layout, Loader } from 'components'
import { useGetFingerprints } from 'data-fetcher'
import { Operations } from './components'

const Dashboard = () => {
  const { data: fingerprints, isLoading } = useGetFingerprints()
  const navigate = useNavigate()

  return (
    <Layout>
      {isLoading ? (
        <Loader fullPageCenter />
      ) : !fingerprints?.length ? (
        <EmptyNote
          buttonLabel="Create Fingerprint"
          copy="Seems like you haven’t created any fingerprints yet."
          fullPageCenter
          onButtonClick={() => navigate('/fingerprints')}
          icon="fingerprint"
        />
      ) : (
        <Operations />
        // <TabsRoot defaultValue="operations">
        //   <TabsList
        //     tabs={[
        //       { name: 'Operations', value: 'operations' }
        //       { name: 'Insights', value: 'insights' }
        //     ]}
        //   />
        //   <TabsContent value="operations">
        //   </TabsContent>
        // </TabsRoot>
      )}
    </Layout>
  )
}

export default Dashboard
