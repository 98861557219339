import { Keys } from 'config'
import { createId, logError } from 'shared'
import { Fingerprint } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type CreateFingerprints = Omit<Fingerprint, 'id'>[]

const createFingerprints = async (fingerprints: CreateFingerprints) => {
  const { data, error } = await apiClient
    .from<Keys.Fingerprints, Database['public']['Tables'][Keys.Fingerprints]>(Keys.Fingerprints)
    .insert(
      fingerprints.map(({ creator, name, team }) => ({ creator, id: createId(), name, team }))
    )

  if (error) {
    logError('createFingerprints failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}

export default createFingerprints
