import { Keys } from 'config'
import { log } from 'shared'

import { CaseStatus } from '../../../types'
import { isBadResponseStatus } from '../../api.helpers'
import { Database } from '../../api.types'
import apiClient from '../../apiClient'

const getCasesCount = async (caseStatuses?: CaseStatus[]): Promise<number> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id
  const userTeam = session.data.session?.user.user_metadata.team

  log('🔍 API: getCases', { onlineUserId })

  let query = apiClient
    .from<Keys.Cases, Database['public']['Tables'][Keys.Cases]>(Keys.Cases)
    .select('*', { count: 'exact', head: true })
    .eq('team', userTeam)

  if (caseStatuses) {
    query = query.in('status', caseStatuses)
  }

  const { count, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getCases failed with status: ${status} and error: ${error}`)
  }

  return count ?? 0
}

export default getCasesCount
