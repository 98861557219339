import { Card as BaseCard, Loader as BaseLoader, EmptyNote, LoadMore } from 'components'

import styled, { css } from 'styled-components'
import { FontSizes } from 'theme'

export const Card = styled(BaseCard)`
  margin-top: 2rem;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`

export const LabelsText = styled.span`
  display: block;
  font-size: ${FontSizes.Small};
  line-height: 1.6;
`

const sharedNoteStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 25vh;
  width: 100%;
`

export const NoData = styled(EmptyNote)`
  ${sharedNoteStyles};
`

export const Loader = styled(BaseLoader)`
  ${sharedNoteStyles};
`

export const ControversiesLoadMore = styled(LoadMore)`
  margin: 4rem 0 1rem;
`
