import { Keys } from 'config'
import { log } from 'shared'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from '../../api.helpers'
import { Relevance } from 'types'

export type GetRelevance = {
  controversy: string
  user?: string
}

const getRelevance = async ({ controversy, user }: GetRelevance): Promise<Relevance | null> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getRelevance')

  const { data, error, status } = await apiClient
    .from<Keys.Relevance, Database['public']['Tables'][Keys.Relevance]>(Keys.Relevance)
    .select('*')
    .eq('user', user ?? (onlineUserId || ''))
    .eq('controversy', controversy)

  if (isBadResponseStatus(status)) {
    throw new Error(`getRelevance failed with status: ${status} and error: ${error}`)
  }

  return data?.[0] ?? null
}

export default getRelevance
