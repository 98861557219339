import styled, { keyframes } from 'styled-components'
import { Description, Root, Title, Viewport } from '@radix-ui/react-toast'
import { BorderRadius, Colors, FontSizes } from 'theme'
import { ToastType } from './ToastProvider'

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
`

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
`

const toastColors: Record<ToastType, `#${string}`> = {
  success: '#e3f4eb',
  error: '#FED8D2'
}

export const ToastRoot = styled(Root)<{ $toastType: ToastType }>`
  background: ${({ $toastType }) => ($toastType ? toastColors[$toastType] : '#fff')};
  border-radius: ${BorderRadius.Tiles};
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  padding: 1rem;

  &[data-state='open'] {
    animation: ${slideIn} 250ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    animation: ${hide} 150ms ease-in;
  }

  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='end'] {
    animation: ${swipeOut} 100ms ease-out;
  }
`

export const ToastTitle = styled(Title)`
  color: ${Colors.Text};
  font-family: 'Montserrat', -apple-system, Helvetica, Arial, sans-serif;
  font-size: ${FontSizes.Small};
  font-weight: 600;
`

export const ToastDescription = styled(Description)`
  color: ${Colors.TextLight};
  font-size: ${FontSizes.Small};
`

export const ToastViewport = styled(Viewport)`
  --viewport-padding: 25px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`
