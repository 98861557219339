import { useQuery } from '@tanstack/react-query'
import { getDelabeling } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

export const useGetDelabeling = ({
  controversyId,
  options,
  userId
}: {
  controversyId: string
  options?: FetcherOptions
  userId?: string
}) => {
  return useQuery(
    [Keys.Delabeling, controversyId, userId ?? null],
    () => getDelabeling({ controversyId, userId }),
    getDefaultFetcherOptions(options)
  )
}
