import styled from 'styled-components'
import { RiLink } from 'react-icons/ri'
import { Colors, FontSizes } from 'theme'

export const Wrapper = styled.div``

export const LinkIcon = styled(RiLink)`
  color: ${Colors.Primary};
  height: 1rem;
  width: 1rem;
`

export const LinkLabel = styled.span`
  color: ${Colors.Text};
  font-size: ${FontSizes.Small};
  font-weight: 400;
`

export const Link = styled.a`
  align-items: center;
  display: flex;
  gap: 0.375rem;
  margin-top: 0.75rem;

  &:hover {
    ${LinkIcon} {
      color: ${Colors.PrimaryHover};
    }
    ${LinkLabel} {
      color: ${Colors.TextHover};
    }
  }
`
