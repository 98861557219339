import styled from 'styled-components'

import { Link } from 'react-router-dom'
import { Colors, FontSizes } from 'theme'

import Meta from '../Meta'

export const Root = styled.article``

export const StyledMeta = styled(Meta)`
  margin-bottom: 0.375rem;
`

export const Title = styled(Link)`
  color: ${Colors.Text};
  font-size: ${FontSizes.Base};
  font-weight: 600;
  margin: 0.375rem 0 0.5rem;
  padding-bottom: 0.125rem;

  &:hover {
    color: ${Colors.Primary};
  }
`

export const Text = styled.p`
  margin-top: 0.75em;
`

export const LabelsSlot = styled.div`
  margin-top: 1rem;
`

export const ActionsSlot = styled.div`
  align-items: center;
  display: flex;
  gap: 0.625rem;
  margin-top: 1.25rem;
`
