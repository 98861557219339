import { BusinessUnit } from 'types'
import { Keys } from 'config'

import { log } from 'shared'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from 'api/api.helpers'

export const getBusinessUnits = async (): Promise<Array<BusinessUnit>> => {
  const session = await apiClient.auth.getSession()
  const userId = session.data.session?.user.id

  log('🔍 API: getBusinessUnits', { userId })

  const { data, error, status } = await apiClient
    .from<Keys.BusinessUnits, Database['public']['Tables'][Keys.BusinessUnits]>(Keys.BusinessUnits)
    .select()

  if (isBadResponseStatus(status)) {
    throw new Error(`getBusinessUnits failed with status: ${status} and error: ${error}`)
  }

  return (data as BusinessUnit[]) ?? []
}
