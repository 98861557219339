import { Keys } from 'config'
import { createId, logError } from 'shared'
import { Case } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type CreateCase = Omit<Case, 'id' | 'createdAt' | 'updatedAt'>

const createCase = async (newCase: CreateCase) => {
  const session = await apiClient.auth.getSession()
  const userId = session.data.session!.user.id
  const userTeam = session.data.session?.user.user_metadata.team

  const { links } = newCase

  const { data, error } = await apiClient
    .from<Keys.Cases, Database['public']['Tables'][Keys.Cases]>(Keys.Cases)
    .insert({
      ...newCase,
      id: createId(),
      links: links ? JSON.stringify(links) : undefined,
      name: newCase.name || 'New Case',
      creator: userId,
      team: userTeam
    })

  if (error) {
    logError('createCase failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}

export default createCase
