import { Keys } from 'config'
import { createId, logError } from 'shared'
import { Label } from 'types'
import apiClient from '../../apiClient'

export type CreateLabels = (Omit<Label, 'color' | 'id'> & { color?: string })[]

const createLabels = async (labels: CreateLabels) => {
  const { data, error } = await apiClient.from(Keys.Labels).upsert(
    labels.map(({ color, creator, fingerprint, name, team }) => ({
      color,
      creator,
      fingerprint,
      id: createId(),
      name,
      team
    }))
  )

  if (error) {
    logError('createLabels failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}

export default createLabels
