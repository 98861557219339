import { useInfiniteQuery } from '@tanstack/react-query'
import { ControversiesWithRelevanceProps, getControversiesWithRelevance } from 'api'
import { Keys } from 'config'
import { useGetRelevanceCount } from 'data-fetcher/relevance'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'

type Props = ControversiesWithRelevanceProps & FetcherOptions

const PAGE_SIZE = 30

const useGetPaginatedControversiesWithRelevance = ({ relevant, ...options }: Props) => {
  const { data: count } = useGetRelevanceCount({ relevant })

  return useInfiniteQuery(
    [relevant ? Keys.ControversiesPaginatedRelevant : Keys.ControversiesPaginatedIrrelevant],
    ({ pageParam = 0 }) =>
      getControversiesWithRelevance({
        range: { from: pageParam * PAGE_SIZE, to: (pageParam + 1) * PAGE_SIZE - 1 },
        relevant
      }),
    {
      ...getDefaultFetcherOptions(options),
      getNextPageParam: (lastPage, pages) => {
        const amountOfFetchedItems = pages.reduce((acc, page) => acc + page.length, 0)
        if (count === 0 || (count && !(amountOfFetchedItems < count))) {
          return undefined
        }
        return pages.length
      }
    }
  )
}

export default useGetPaginatedControversiesWithRelevance
