import { RiCheckLine, RiBilibiliLine } from 'react-icons/ri'
import styled, { css } from 'styled-components'
import { AccordionHeader as BaseAccordionHeader } from 'components'
import { Colors, FontSizes } from 'theme'

export const AccordionHeader = styled(BaseAccordionHeader)`
  justify-content: space-between;
`

export const Headline = styled.span`
  font-size: ${FontSizes.Base};
  font-weight: 600;
`

export const Groups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Group = styled.div``

export const Fingerprint = styled.span`
  display: block;
  font-size: ${FontSizes.Small};
  font-weight: 600;
  margin-bottom: 1rem;
  padding-top: 2rem;
`

export const Table = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
`

export const TableHead = styled.thead``

export const TableHeading = styled.th`
  font-size: ${FontSizes.Small};
  padding: 0.5rem;
  text-align: left;
`

export const TableData = styled.td`
  font-size: ${FontSizes.Small};
  padding: 0.5rem;
  width: auto;
`

export const TableRow = styled.tr`
  ${TableData} {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  ${TableHeading} {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`

export const TableBody = styled.tbody`
  ${TableRow} {
    border-top: 1px solid ${Colors.Divider};
  }
`

export const IconButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`

const sharedIconStyles = css`
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
`

export const IconButton = styled.button<{ $bg: string; $color: string }>`
  ${sharedIconStyles};

  background-color: ${({ $bg }) => $bg};
  color: ${({ $color }) => $color};

  &:hover {
    background-color: ${({ $color }) => $color};

    svg {
      color: #fff;
    }
  }
`

export const TableDataInnerWrapper = styled.div`
  display: flex;
`

export const AcceptAllCheckIconWrapper = styled.div`
  ${sharedIconStyles};

  background-color: ${Colors.PrimaryLight};
  margin-right: 0.5rem;
`

export const AcceptAllCheckIcon = styled(RiCheckLine)`
  color: ${Colors.Primary};
`

export const AcceptButtonLabel = styled.span`
  color: ${Colors.Primary};
  font-size: ${FontSizes.XSmall};
  font-weight: 600;
`

export const AcceptAllButton = styled.button`
  align-items: center;
  display: flex;
  margin-top: 2rem;

  &:hover {
    ${AcceptButtonLabel} {
      text-decoration: underline;
    }
  }
`

export const Row = styled.div`
  align-items: center;
  display: flex;
`

export const AiSuggestionsIcon = styled(RiBilibiliLine)`
  color: ${Colors.Text};
  height: 1rem;
  margin: 0 0.5rem 0 0;
  width: 1rem;
`
