import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteFingerprint } from 'api'
import { Keys } from 'config'
import { useToast } from 'stores'
import { Fingerprint } from 'types'

const useDeleteFingerprint = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(deleteFingerprint, {
    onMutate: async fingerprint => {
      await queryClient.cancelQueries([Keys.Fingerprints])

      const previousFingerprints =
        queryClient.getQueryData<Fingerprint[]>([Keys.Fingerprints]) ?? []

      queryClient.setQueryData(
        [Keys.Fingerprints],
        (previousFingerprints ?? []).filter(({ id }) => id !== fingerprint)
      )

      return { previousFingerprints }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([Keys.Fingerprints], context?.previousFingerprints ?? [])
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: () => {
      queryClient.invalidateQueries([Keys.Fingerprints])
      showToast({ description: 'Fingerprint deleted successfully', title: 'Success ✅' })
    }
  })
}

export default useDeleteFingerprint
