import { createContext } from 'react'

const AuthContext = createContext<{
  userId: string
  userTeam: string
}>({
  userId: '',
  userTeam: ''
})

export default AuthContext
