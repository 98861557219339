import { Keys } from 'config'
import { log } from 'shared'

import apiClient from '../../apiClient'
import { isBadResponseStatus } from '../../api.helpers'

const getTopLabels = async (): Promise<Array<{ count: number; name: string }>> => {
  log('🔍 API: getTopLabels')

  const session = await apiClient.auth.getSession()
  const userTeam = session.data.session?.user.user_metadata.team

  const { data, error, status } = await apiClient
    .from(Keys.TopLabels)
    .select('*')
    .eq('team', userTeam)

  if (isBadResponseStatus(status)) {
    throw new Error(`getTopLabels failed with status: ${status} and error: ${error}`)
  }

  // @ts-ignore
  return data ?? []
}

export default getTopLabels
