import { Button, DocExcerpt as DocExcerptBase } from 'components'
import { useCreateControversy } from 'data-fetcher'
import { useUpdateRegulation } from 'data-fetcher/regulations'
import { FC, useEffect, useState } from 'react'
import { Regulation } from 'types'

import { RiCheckFill } from 'react-icons/ri'
import { parseStringIfValidJson } from '@intuitive-ai/utils/parseStringIfValidJson'

export const DocExcerpt: FC<{
  data: Omit<Regulation, 'created_at' | 'document_json' | 'metadata_json' | 'checksum'> & {
    href: string
  }
}> = ({ data }) => {
  const { date, document_type, fulltext, id, is_controversy, source, title, url, href } = data

  const [isControversy, setIsControversy] = useState(is_controversy)

  const { mutateAsync: createControversy, isLoading: isCreatingControversy } =
    useCreateControversy()

  const { mutate: updateRegulation, isSuccess: isSuccessUpdateRegulation } = useUpdateRegulation()

  useEffect(() => {
    if (isSuccessUpdateRegulation) {
      setIsControversy(prev => !prev)
    }
  }, [isSuccessUpdateRegulation])

  const parsed = parseStringIfValidJson(fulltext)
  const paragraphs = Array.isArray(parsed)
    ? parsed.map(({ paragraph }) => paragraph)
    : [parsed]
  const validFulltext = paragraphs.join(' ')

  return (
    <DocExcerptBase
      href={href}
      meta={{ date, source, documentType: document_type, url }}
      title={title}
      text={validFulltext}
      actionsSlot={
        <Button
          disabled={Boolean(isControversy)}
          iconStart={isControversy && <RiCheckFill style={{ marginRight: '.75rem' }} />}
          onClick={async () => {
            try {
              if (!isControversy) {
                await createControversy({
                  controversy: {
                    date,
                    document_type,
                    fulltext,
                    source,
                    title,
                    url,
                    abstract: null
                  }
                })
              }
              updateRegulation({
                regulationId: id,
                updatedRegulation: { title, is_controversy: !isControversy }
              })
            } catch (error) {
              // ignore
            }
          }}
          isLoading={isCreatingControversy}
        >
          Save to Reg-Feed
        </Button>
      }
    />
  )
}
