import { useQuery } from '@tanstack/react-query'
import { getAllLabels } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetAllLabels = (options?: FetcherOptions) => {
  return useQuery([Keys.Labels], getAllLabels, getDefaultFetcherOptions(options))
}

export default useGetAllLabels
