import { useQuery } from '@tanstack/react-query'
import { getCase } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'

const useGetCase = (id?: string, options?: FetcherOptions) => {
  return useQuery([Keys.Cases, id], () => getCase(id), getDefaultFetcherOptions(options))
}

export default useGetCase
