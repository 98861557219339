import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AddLabels,
  AvailableLabels,
  Card,
  EmptyNote,
  TextWithLabels,
  Layout,
  Loader,
  SuggestedLabels,
  TabsContent,
  TabsRoot
} from 'components'
import { useGetControversy, useGetUsersWithLabeling } from 'data-fetcher'
import { Label, Labeling } from 'types'

import {
  AddLabelsIcon,
  Grid,
  MainColumn,
  Nav,
  NavItem,
  NavItemIconNext,
  NavItemIconPrev,
  NavItemLabel,
  SideColumn,
  StickyCard,
  TabsList,
  Wrapper
} from './Controversy.styles'
import { useGetPrevAndNextControversies } from './Controversy.hooks'
import { useAuth } from 'stores'

const Controversy = () => {
  const params = useParams()
  const controversyId = params?.id

  const { userId: currentUserId } = useAuth()

  const [activeFingerprintId, setActiveFingerprintId] = useState<string | undefined>()
  const [activeFingerprintName, setActiveFingerprintName] = useState<string | undefined>()
  const [activeLabel, setActiveLabel] = useState<Label | undefined>()
  const [hoveredLabelingId, setHoveredLabelingId] = useState<string | undefined>()
  const [userLabeling, setUserLabeling] = useState<Labeling[] | null>(null)

  const { next, prev } = useGetPrevAndNextControversies({ controversy: controversyId ?? '' })

  const { data: controversy, isLoading } = useGetControversy(controversyId)

  const { data: usersWithLabeling = [] } = useGetUsersWithLabeling({ controversyId })

  const handleLabelClick = ({
    fingerprintId,
    fingerprintName,
    label
  }: {
    fingerprintId: string
    fingerprintName: string
    label: Label
  }) => {
    if (activeLabel?.id === label.id) {
      setActiveFingerprintId(undefined)
      setActiveFingerprintName(undefined)
      setActiveLabel(undefined)
    } else {
      setActiveFingerprintId(fingerprintId)
      setActiveFingerprintName(fingerprintName)
      setActiveLabel(label)
    }
  }

  const handleLabelingChange = (labeling: Labeling[]) => {
    setUserLabeling(labeling)
  }

  const tabs = useMemo(
    () =>
      usersWithLabeling
        .filter(userId => userId !== currentUserId)
        .map((userId, i) => ({ name: `Colleague ${i + 1}`, value: userId })),
    [usersWithLabeling]
  )

  return (
    <Layout goBackHref="/controversies">
      {isLoading ? (
        <Loader fullPageCenter />
      ) : !controversy ? (
        <EmptyNote copy="No data!" fullPageCenter icon="data" />
      ) : (
        <Wrapper>
          <Nav>
            <NavItem $active={Boolean(prev)} to={prev}>
              <NavItemIconPrev />
              <NavItemLabel>Previous</NavItemLabel>
            </NavItem>
            <NavItem $active={Boolean(next)} to={next}>
              <NavItemLabel>Next</NavItemLabel>
              <NavItemIconNext />
            </NavItem>
          </Nav>

          <Grid>
            <MainColumn>
              <TabsRoot defaultValue="default">
                <TabsList tabs={[{ name: 'My Labels', value: 'default' }, ...tabs]} />

                <TabsContent value="default">
                  <Card>
                    <TextWithLabels
                      activeFingerprintId={activeFingerprintId}
                      activeFingerprintName={activeFingerprintName}
                      activeLabel={activeLabel}
                      controversy={controversy}
                      focusedLabelingId={hoveredLabelingId}
                      includeAiLabeling
                    />
                  </Card>
                </TabsContent>

                {tabs.map(({ value }) => (
                  <TabsContent key={`controversy-tab-${value}`} value={value}>
                    <Card>
                      <TextWithLabels
                        activeFingerprintId={activeFingerprintId}
                        activeFingerprintName={activeFingerprintName}
                        activeLabel={activeLabel}
                        controversy={controversy}
                        focusedLabelingId={hoveredLabelingId}
                        includeAiLabeling
                        userId={value}
                      />
                    </Card>
                  </TabsContent>
                ))}
              </TabsRoot>
            </MainColumn>
            <SideColumn>
              <Card>
                <SuggestedLabels
                  ai
                  controversyId={controversy.id}
                  onLabelingHovered={({ hovered, labeling }) => {
                    /**
                     * The received order of (un)hovered items does not always reflect
                     * the actual order. That's why we need explicit checks
                     * and callbacks in setState.
                     */
                    setHoveredLabelingId(prev => {
                      if (hovered) {
                        if (labeling) {
                          return labeling
                        }
                      } else {
                        if (labeling && prev && labeling === prev) {
                          return undefined
                        }
                      }

                      return prev
                    })
                  }}
                  userLabeling={userLabeling}
                />
              </Card>
              <Card>
                <AvailableLabels
                  controversyId={controversy.id}
                  onLabelingChange={handleLabelingChange}
                  onLabelingHovered={({ hovered, labeling }) => {
                    /**
                     * The received order of (un)hovered items does not always reflect
                     * the actual order. That's why we need explicit checks
                     * and callbacks in setState.
                     */
                    setHoveredLabelingId(prev => {
                      if (hovered) {
                        if (labeling) {
                          return labeling
                        }
                      } else {
                        if (labeling && prev && labeling === prev) {
                          return undefined
                        }
                      }

                      return prev
                    })
                  }}
                />
              </Card>
              <StickyCard icon={<AddLabelsIcon />} title="Add Labels">
                <AddLabels activeLabelId={activeLabel?.id} onLabelClick={handleLabelClick} />
              </StickyCard>
            </SideColumn>
          </Grid>
        </Wrapper>
      )}
    </Layout>
  )
}

export default Controversy
