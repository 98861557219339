import { useQuery } from '@tanstack/react-query'
import { getControversy } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'

const useGetControversy = (id?: string, options?: FetcherOptions) => {
  return useQuery(
    [Keys.Controversies, id],
    () => getControversy(id),
    getDefaultFetcherOptions(options)
  )
}

export default useGetControversy
