import { useGetAllLabels, useGetColors } from 'data-fetcher'
import { FC, ReactNode, useMemo } from 'react'
import { isValidNumber, isValidNumberAboveZero } from 'shared'
import ColorsContext from './ColorsContext'

const Colors: FC<{ children?: ReactNode }> = ({ children }) => {
  const { data: colors } = useGetColors()
  const { data: labels } = useGetAllLabels()

  const numOfAvailableColors = useMemo(() => colors?.length ?? 0, [colors?.length])
  const numOfLabels = useMemo(() => labels?.length ?? 0, [labels?.length])

  const getNextLabelColorId = () => {
    if (!isValidNumberAboveZero(numOfAvailableColors) || !isValidNumber(numOfLabels)) {
      return undefined
    }

    const nextColorIndex = numOfLabels % numOfAvailableColors

    return colors?.[nextColorIndex]?.id
  }

  return <ColorsContext.Provider value={{ getNextLabelColorId }}>{children}</ColorsContext.Provider>
}

export default Colors
