import { Database } from 'api/api.types'
import { Keys } from 'config'
import { log } from 'shared'
import { Controversy } from 'types'

import { isBadResponseStatus } from '../../api.helpers'
import apiClient from '../../apiClient'

const getControversy = async (controversyId?: string): Promise<Controversy | null> => {
  if (!controversyId) {
    return null
  }

  log('🔍 API: getControversy')

  const { data, error, status } = await apiClient
    .from<Keys.Controversies, Database['public']['Tables'][Keys.Controversies]>(Keys.Controversies)
    .select('*')
    .eq('id', controversyId)
    .single()

  if (isBadResponseStatus(status)) {
    throw new Error(`getControversy failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return null
  }

  return {
    abstract: data.abstract,
    date: data.date,
    documentType: data.document_type,
    fulltext: data.fulltext,
    id: data.id,
    source: data.source,
    title: data.title,
    url: data.url
  } as Controversy
}

export default getControversy
