import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const Row: FC<{ alignItems?: string; children: ReactNode; className?: string; gap?: string }> = ({
  alignItems,
  children,
  className,
  gap
}) => (
  <Wrapper $align={alignItems ?? 'center'} $gap={gap} className={className}>
    {children}
  </Wrapper>
)

export default Row

const Wrapper = styled.div<{ $align: string; $gap?: string }>`
  align-items: ${({ $align }) => $align};
  display: flex;
  flex: 1;
  gap: ${({ $gap }) => $gap ?? '0'};
`
