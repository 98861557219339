import { Keys } from 'config'
import { logError } from 'shared'
import { Source } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type UpdateSource = {
  sourceId: string
  updatedSource: Partial<Source>
}

export const updateSource = async ({ sourceId, updatedSource }: UpdateSource) => {
  const { sourceType, ...source } = updatedSource

  const { data, error } = await apiClient
    .from<Keys.Sources, Database['public']['Tables'][Keys.Sources]>(Keys.Sources)
    .update({
      ...source,
      source_type: sourceType
    })
    .eq('id', sourceId)

  if (error) {
    logError('updateSource failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}
