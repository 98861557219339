import { useMemo } from 'react'
import { BarList } from '@tremor/react'
import { EmptyNote, Loader } from 'components'
import {
  Card,
  Cards,
  Center,
  ChartWrapper,
  Column,
  Heading,
  Label,
  Metric,
  TwoColumns,
  Wrapper
} from './Operations.styles'
import {
  useGetOpenControversiesCount,
  useGetCasesCount,
  useGetRelevanceCount,
  useGetTopLabels
} from 'data-fetcher'
import { CaseStatus } from 'types'

const Operations = () => {
  const { data: amountOfControversies } = useGetOpenControversiesCount()
  const { data: openCases } = useGetCasesCount({
    statuses: [CaseStatus.InProgress, CaseStatus.New, CaseStatus.Review]
  })
  const { data: closedCases } = useGetCasesCount({ statuses: [CaseStatus.Closed] })
  const { data: relevanceCount } = useGetRelevanceCount()
  const { data: topLabelsRaw, isLoading } = useGetTopLabels()

  const topLabels =
    useMemo(
      () => topLabelsRaw?.map(({ count, name }) => ({ name, value: count })),
      [topLabelsRaw]
    ) ?? []

  return (
    <Wrapper>
      <Column>
        <Heading>Controversies</Heading>

        <Cards>
          <Card href="/regfeed">
            <Metric>{`${amountOfControversies ?? 0}`}</Metric>
            <Label>New entries in Reg-Feed</Label>
          </Card>
          <Card href="/regfeed">
            <Metric>{`${relevanceCount ?? 0}`}</Metric>
            <Label>Revised entries in Reg-Feed</Label>
          </Card>
        </Cards>
      </Column>

      <Column>
        <Heading>Cases</Heading>

        <Cards>
          <Card href="/cases">
            <Metric>{`${openCases ?? 0}`}</Metric>
            <Label>Open Cases</Label>
          </Card>
          <Card href="/cases?tab=closed">
            <Metric>{`${closedCases ?? 0}`}</Metric>
            <Label>Closed Cases</Label>
          </Card>
        </Cards>
      </Column>

      <TwoColumns>
        <Heading>Trending Topics</Heading>
        <Card $shouldNotCenter>
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : !isLoading && !topLabels?.length ? (
            <Center>
              <EmptyNote copy="No labels data" icon="label" />
            </Center>
          ) : (
            <ChartWrapper>
              <BarList data={topLabels} color="gray" showAnimation={true} />
            </ChartWrapper>
          )}
        </Card>
      </TwoColumns>
    </Wrapper>
  )
}

export default Operations
