const isDevEnv = () => process.env.NODE_ENV === 'development'

export const log = (
  message?: any,
  data?: {
    [key: string]: any
  }
) => {
  if (isDevEnv()) {
    if (data) {
      console.log(`ℹ️  ${message}`, data)
    } else {
      console.log(`ℹ️  ${message}`)
    }
  } else {
    console.info(message)
  }
}

export const logError = (
  message: any,
  data?: {
    [key: string]: any
  }
) => {
  try {
    if (isDevEnv()) {
      if (data) {
        console.log(`❌  ${message}`, data)
      } else {
        console.log(`❌  ${message}`)
      }
    } else {
      console.error(message)
    }
  } catch (error) {
    console.log('Logging an error caused an error ¯_(ツ)_/¯')
  }
}
