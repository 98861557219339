import { useState } from 'react'

import { Button, Card, Layout, Row, TextInput } from 'components'
import { useGetFingerprints, useCreateFingerprints } from 'data-fetcher'
import { useAuth } from 'stores'

import { Fingerprint } from './components'
import { Wrapper } from './Fingerprints.styles'

const Fingerprints = () => {
  const { userId, userTeam } = useAuth()

  const [fingerprintName, setFingerprintName] = useState('')

  const { mutate: createFingerprints } = useCreateFingerprints()
  const { data: fingerprints } = useGetFingerprints()

  const handleSave = async () => {
    if (fingerprintName.length > 1) {
      createFingerprints([{ creator: userId!, name: fingerprintName, team: userTeam }])
      setFingerprintName('')
    }
  }

  return (
    <Layout>
      <Wrapper>
        <Card title="Create Fingerprint">
          <Row alignItems="flex-end" gap="0.5rem">
            <TextInput
              label="Fingerprint Name"
              onChange={input => setFingerprintName(input)}
              onSubmit={handleSave}
              placeholder={'Enter name...'}
              value={fingerprintName}
            />
            <Button onClick={handleSave}>Save</Button>
          </Row>
        </Card>
        {fingerprints?.map(fingerprint => (
          <Fingerprint fingerprint={fingerprint} key={`fingerprint-${fingerprint.id}`} />
        ))}
      </Wrapper>
    </Layout>
  )
}

export default Fingerprints
