import styled from 'styled-components'
import { BorderRadius, Colors, FontSizes } from 'theme'

import { RiCheckLine } from 'react-icons/ri'

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<{ checked?: boolean }>`
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  border: 1px solid ${Colors.InputBorder};
  border-radius: ${BorderRadius.Controls};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ checked }) => (checked ? Colors.ButtonPrimaryBg : Colors.Surface)};
  transition: background-color 100ms ease;
`

export const Check = styled(RiCheckLine)`
  color: #fff;
  position: absolute;
`

export const Label = styled.label<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  color: ${Colors.Text};
  font-size: ${FontSizes.XSmall};
  font-weight: 600;

  &:hover {
    ${StyledCheckbox} {
      background: ${({ checked }) => (checked ? Colors.ButtonPrimaryBg : Colors.Base)};
    }
  }
`
