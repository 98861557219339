import { useQuery } from '@tanstack/react-query'
import { getCaseBusinessUnits } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

export const useGetCaseBusinessUnits = (filters: { caseId: string; options?: FetcherOptions }) => {
  const { caseId, options } = filters || {}

  return useQuery(
    [Keys.CaseBusinessUnits, caseId],
    () => getCaseBusinessUnits(caseId),
    getDefaultFetcherOptions(options)
  )
}
