import styled from 'styled-components'
import { RiFingerprintLine, RiFileForbidLine, RiPriceTag3Line } from 'react-icons/ri'
import { Button, TOP_BAR_HEIGHT } from 'components'
import { Colors } from 'theme'
import { FC } from 'react'

const iconProps = { color: Colors.Primary, size: 20 }

const EmptyNote: FC<{
  buttonLabel?: string
  className?: string
  copy: string
  fullPageCenter?: boolean
  href?: string
  icon: 'data' | 'fingerprint' | 'label'
  onButtonClick?: () => void
}> = ({ buttonLabel, className, copy, fullPageCenter, href, icon, onButtonClick }) => {
  return (
    <Wrapper className={className} $fullPageCenter={fullPageCenter}>
      <IconWrapper>
        {icon === 'data' ? (
          <RiFileForbidLine {...iconProps} />
        ) : icon === 'label' ? (
          <RiPriceTag3Line {...iconProps} />
        ) : (
          <RiFingerprintLine {...iconProps} />
        )}
      </IconWrapper>

      <Copy>{copy}</Copy>

      {(href || onButtonClick) && (
        <Button href={href} onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}
    </Wrapper>
  )
}

export default EmptyNote

const Wrapper = styled.div<{ $fullPageCenter?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  height: ${({ $fullPageCenter }) => ($fullPageCenter ? `calc(90% - ${TOP_BAR_HEIGHT})` : 'unset')};
  width: ${({ $fullPageCenter }) => ($fullPageCenter ? '100%' : 'unset')};
`

const IconWrapper = styled.div`
  align-items: center;
  background: ${Colors.PrimaryLight};
  border-radius: 8px;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
`

const Copy = styled.span`
  text-align: center;
`
