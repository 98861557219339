import { Button, Divider, DocExcerpt } from 'components'
import { useGetPaginatedControversiesWithRelevance } from 'data-fetcher'
import { Fragment } from 'react'

import {
  Card,
  ControversiesLoadMore,
  LabelsText,
  List,
  Loader,
  NoData
} from '../Controversies.styles'
import { parseStringIfValidJson } from '@intuitive-ai/utils'

export const RelevantControversies = () => {
  const {
    data: controversies,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useGetPaginatedControversiesWithRelevance({ keepPreviousData: true, relevant: true })

  if (isLoading) {
    return <Loader />
  }

  if (!Array.isArray(controversies?.pages) || !controversies?.pages[0]?.length) {
    return <NoData copy="No data!" icon="data" />
  }

  return (
    <>
      <Card>
        <List>
          <>
            {controversies?.pages?.map((page, index) => {
              return (
                <Fragment key={index}>
                  {page?.map(
                    (
                      { date, documentType, fingerprints, fulltext, id, source, title, url },
                      index
                    ) => {
                      const href = `/controversies/${id}`

                      const parsed = parseStringIfValidJson(fulltext)
                      const paragraphs = Array.isArray(parsed)
                        ? parsed.map(({ paragraph }) => paragraph)
                        : [parsed]
                      const validFulltext = paragraphs.join(' ')

                      return (
                        <Fragment key={`controversy-${id}`}>
                          {index !== 0 && <Divider />}
                          <DocExcerpt
                            href={href}
                            meta={{ date, documentType, source, url }}
                            title={title}
                            text={validFulltext}
                            labelsSlot={
                              <>
                                {fingerprints?.map(
                                  ({ fingerprintId, fingerprintName, labelNames }) => (
                                    <LabelsText key={`fingerprint-${fingerprintId}`}>
                                      <b>{fingerprintName}:</b> {labelNames}
                                    </LabelsText>
                                  )
                                )}
                              </>
                            }
                            actionsSlot={<Button href={href}>Details</Button>}
                          />
                        </Fragment>
                      )
                    }
                  )}
                </Fragment>
              )
            })}
          </>
        </List>
      </Card>
      {hasNextPage && <ControversiesLoadMore isLoading={isFetching} onLoadMore={fetchNextPage} />}
    </>
  )
}
