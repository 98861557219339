import { Keys } from 'config'
import { logError } from 'shared'
import { Regulation } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type UpdateRegulation = {
  regulationId: string
  updatedRegulation: Partial<Regulation>
}

export const updateRegulation = async ({ regulationId, updatedRegulation }: UpdateRegulation) => {
  console.log('update regulation with id:', regulationId)
  console.log('data:', updatedRegulation)

  const { data, error } = await apiClient
    .from<Keys.Regulations, Database['public']['Tables'][Keys.Regulations]>(Keys.Regulations)
    .update({
      ...updatedRegulation
    })
    .eq('id', regulationId)

  if (error) {
    logError('updateRegulation failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}
