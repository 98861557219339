import { Label } from 'types'
import { Keys } from 'config'

import { log } from 'shared'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from 'api/api.helpers'

const getLabelsForFingerprint = async ({
  fingerprint
}: {
  fingerprint: string
}): Promise<Array<Label>> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getLabelsForFingerprint', { onlineUserId })

  const {
    data: labelsData,
    error,
    status
  } = await apiClient
    .from<Keys.Labels, Database['public']['Tables'][Keys.Labels]>(Keys.Labels)
    .select(
      `
      color_active:colors(active),
      color_passive:colors(passive),
      creator,
      fingerprint,
      id,
      name,
      team
    `
    )
    .eq('fingerprint', fingerprint)
    .order('updated_at', { ascending: true })

  if (isBadResponseStatus(status)) {
    throw new Error(`getLabelsForFingerprint failed with status: ${status} and error: ${error}`)
  }

  const labels = labelsData?.map(
    ({ color_active, color_passive, creator, fingerprint, id, name, team }) =>
      ({
        color: {
          active: !Array.isArray(color_active) ? color_active?.active : undefined,
          passive: !Array.isArray(color_passive) ? color_passive?.passive : undefined
        },
        creator,
        fingerprint,
        id,
        name,
        team
      } as Label)
  )

  return labels ?? []
}

export default getLabelsForFingerprint
