import { Keys } from 'config'
import { logError } from 'shared'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type CreateCaseBusinessUnit = { businessUnitId: string; caseId: string }

export const createCaseBusinessUnit = async ({
  businessUnitId,
  caseId
}: CreateCaseBusinessUnit) => {
  const { data, error } = await apiClient
    .from<Keys.CaseBusinessUnits, Database['public']['Tables'][Keys.CaseBusinessUnits]>(
      Keys.CaseBusinessUnits
    )
    .insert({
      case: caseId,
      unit: businessUnitId
    })

  if (error) {
    logError('createCaseBusinessUnit failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}
