export const parseStringIfValidJson = (input: string) => {
  if (/^\s*(\{|\[)/.test(input)) {
    try {
      const parsed = JSON.parse(input)
      return parsed
    } catch (e) {
      return input
    }
  }

  return input
}