import { Checkbox } from 'components'
import { motion } from 'framer-motion'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { FontSizes } from 'theme'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const shadowMap: Record<'top' | 'bottom' | 'both', FlattenSimpleInterpolation> = {
  top: css`
    mask-image: linear-gradient(0deg, rgba(0, 0, 0) 0px, rgb(0, 0, 0) 85%, rgba(0, 0, 0, 0));
  `,
  bottom: css`
    mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0px, rgb(0, 0, 0) 15%, rgba(0, 0, 0));
  `,
  both: css`
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0px,
      rgb(0, 0, 0) 15%,
      rgb(0, 0, 0) 85%,
      rgba(0, 0, 0, 0)
    );
  `
}

export const ListContainer = styled.ul<{
  $showShadowTop?: boolean
  $showShadowBottom?: boolean
  $height?: number
}>`
  max-height: ${({ $height }) => $height || 160}px;
  margin-block: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  ${({ $showShadowTop, $showShadowBottom }) => {
    if ($showShadowTop && $showShadowBottom) {
      return shadowMap.both
    }
    if ($showShadowTop) {
      return shadowMap.top
    }
    if ($showShadowBottom) {
      return shadowMap.bottom
    }
  }};
`

export const ListItem = styled(motion.li)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  gap: 0.75rem;
  padding-block: 0.5rem;
`

export const StyledCheckbox = styled(Checkbox)`
  flex: 1;
`

export const Count = styled.span`
  font-size: ${FontSizes.XSmall};
  font-weight: bold;
`
