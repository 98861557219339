import { FC } from 'react'

import { MetaProps } from 'components'

import { Root, StyledMeta, Text, Title, LabelsSlot, ActionsSlot } from './DocExcerpt.styles'
import { stripText } from 'shared'

type DocExcerptProps = {
  meta: MetaProps
  title: string
  href: string
  text: string
  labelsSlot?: React.ReactElement
  actionsSlot?: React.ReactElement
}

export const DocExcerpt: FC<DocExcerptProps> = ({
  meta,
  title,
  href,
  text,
  labelsSlot,
  actionsSlot
}) => (
  <Root>
    <StyledMeta {...meta} />
    {Boolean(title) && <Title to={href}>{title}</Title>}
    <Text>{stripText(text, 250)}</Text>
    {labelsSlot && <LabelsSlot>{labelsSlot}</LabelsSlot>}
    {actionsSlot && <ActionsSlot>{actionsSlot}</ActionsSlot>}
  </Root>
)
