import { format } from 'date-fns'
import { logError } from './logger'

export const formatDate = (date?: Date | number | string) => {
  if (!date) {
    return ''
  }

  try {
    if (typeof date === 'string') {
      return format(new Date(date), 'dd.MM.yyyy')
    } else {
      return format(date, 'dd.MM.yyyy')
    }
  } catch (error) {
    logError(error)
  }
}
