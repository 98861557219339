import { Fingerprint } from 'types'
import { Keys } from 'config'

import { log } from 'shared'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from 'api/api.helpers'

const getFingerprints = async (): Promise<Array<Fingerprint>> => {
  const session = await apiClient.auth.getSession()
  const userId = session.data.session?.user.id
  const userTeam = session.data.session?.user.user_metadata.team

  log('🔍 API: getFingerprints', { userId })

  const {
    data: fingerprintsData,
    error,
    status
  } = await apiClient
    .from<Keys.Fingerprints, Database['public']['Tables'][Keys.Fingerprints]>(Keys.Fingerprints)
    .select()
    .eq('team', userTeam)
    .order('updated_at', { ascending: true })

  if (isBadResponseStatus(status)) {
    throw new Error(`getFingerprints failed with status: ${status} and error: ${error}`)
  }

  const fingerprints = fingerprintsData?.map(
    ({ creator, id, name, team }) => ({ creator, id, name, team } as Fingerprint)
  )

  return fingerprints ?? []
}

export default getFingerprints
