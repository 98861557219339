import { apiClient } from 'api'

const uploadFiles = async ({
  files,
  userId,
  visibility
}: {
  files: Array<File>
  userId: string
  visibility: 'private' | 'public'
}) => {
  await Promise.all(
    files.map(file =>
      apiClient.storage
        .from('documents')
        .upload(visibility === 'public' ? `private/${file.name}` : `${userId}/${file.name}`, file)
    )
  )
}

export default uploadFiles
