import { FC } from 'react'
import { Colors, FontSizes } from 'theme'
import { Icon, Label, Wrapper } from './ExternalLink.styles'

const ExternalLink: FC<{
  highlighted?: boolean
  href: string
  label: string
}> = ({ highlighted, href, label }) => (
  <Wrapper href={href} rel="noopener noreferrer" target="_blank">
    <Label>{label}</Label>
    <Icon color={highlighted ? Colors.Primary : Colors.TextLight} size={FontSizes.BaseButton} />
  </Wrapper>
)

export default ExternalLink
