import styled, { FlattenSimpleInterpolation, ThemedCssFunction, css } from 'styled-components'
import { Colors } from 'theme'

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 4rem;
`

export const StyledPagination = styled.ul`
  display: flex;
  list-style-type: none;
  gap: 0.5rem;

  span {
    display: flex;
    align-items: center;
  }
`

const paginationStyles: Record<'active' | 'inactive', FlattenSimpleInterpolation> = {
  active: css`
    background: ${Colors.Primary};
    color: #fff;
  `,
  inactive: css`
    border: 1px solid ${Colors.Secondary};
    color: ${Colors.Primary};
  `
} as const

export const StyledPaginationItem = styled.li<{ active?: boolean }>`
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  ${({ active }) => paginationStyles[active ? 'active' : 'inactive']}
  cursor: pointer;
`
