import { useMutation, useQueryClient } from '@tanstack/react-query'
import { signOut } from 'api'

const useSignOutMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(signOut, {
    onSuccess: () => {
      queryClient.invalidateQueries()
    }
  })
}

export default useSignOutMutation
