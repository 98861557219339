import { Button, Divider, DocExcerpt } from 'components'
import { useCreateOrUpdateRelevance, useGetPaginatedControversies } from 'data-fetcher'
import { Fragment } from 'react'
import { FiX } from 'react-icons/fi'

import { useAuth } from 'stores'
import { Colors } from 'theme'
import {
  Card,
  ControversiesLoadMore,
  LabelsText,
  List,
  Loader,
  NoData
} from '../Controversies.styles'
import { parseStringIfValidJson } from '@intuitive-ai/utils'

const OpenControversies = () => {
  const { userId } = useAuth()
  const {
    data: controversies,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useGetPaginatedControversies({ keepPreviousData: true })

  const { mutate: createRelevance } = useCreateOrUpdateRelevance()

  if (isLoading) {
    return <Loader />
  }

  if (!Array.isArray(controversies?.pages) || !controversies?.pages[0]?.length) {
    return <NoData copy="No data!" icon="data" />
  }

  return (
    <>
      <Card>
        <List>
          <>
            {controversies?.pages?.map((page, index) => {
              return (
                <Fragment key={index}>
                  {page?.map(
                    (
                      { date, documentType, fingerprints, fulltext, id, source, title, url },
                      index
                    ) => {
                      const href = `/controversies/${id}`

                      const parsed = parseStringIfValidJson(fulltext)
                      const paragraphs = Array.isArray(parsed)
                        ? parsed.map(({ paragraph }) => paragraph)
                        : [parsed]
                      const validFulltext = paragraphs.join(' ')

                      return (
                        <Fragment key={`controversy-${id}`}>
                          {index !== 0 && <Divider />}
                          <DocExcerpt
                            href={href}
                            meta={{ date, documentType, source, url }}
                            title={title}
                            text={validFulltext}
                            labelsSlot={
                              <>
                                {fingerprints?.map(
                                  ({ fingerprintId, fingerprintName, labelNames }) => (
                                    <LabelsText key={`fingerprint-${fingerprintId}`}>
                                      <b>{fingerprintName}:</b> {labelNames}
                                    </LabelsText>
                                  )
                                )}
                              </>
                            }
                            actionsSlot={
                              <>
                                <Button href={href}>Details</Button>
                                <Button
                                  onClick={() =>
                                    createRelevance({
                                      controversyId: id,
                                      relevant: false,
                                      userId
                                    })
                                  }
                                  hollow
                                  iconStart={
                                    <FiX
                                      color={Colors.TextLight}
                                      size={16}
                                      strokeWidth="2.75"
                                      style={{ margin: '0 0.125rem 2px 0' }}
                                    />
                                  }
                                >
                                  Not Relevant
                                </Button>
                              </>
                            }
                          />
                        </Fragment>
                      )
                    }
                  )}
                </Fragment>
              )
            })}
          </>
        </List>
      </Card>
      {hasNextPage && <ControversiesLoadMore isLoading={isFetching} onLoadMore={fetchNextPage} />}
    </>
  )
}

export default OpenControversies
