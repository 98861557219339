import { Keys } from 'config'
import { logError } from 'shared'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

export type CreateRelevance = {
  controversyId: string
  userId: string
  relevant: boolean
}

const createOrUpdateRelevance = async ({ controversyId, userId, relevant }: CreateRelevance) => {
  const { data, error } = await apiClient
    .from<Keys.Relevance, Database['public']['Tables'][Keys.Relevance]>(Keys.Relevance)
    .upsert({ controversy: controversyId, user: userId, relevant })

  if (error) {
    logError('createRelevance failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}

export default createOrUpdateRelevance
