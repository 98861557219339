import { SelectItemProps } from '@radix-ui/react-select'
import { FC, forwardRef, ReactNode, Ref } from 'react'
import {
  CheckIcon,
  DownIcon,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectPortal,
  SelectRoot,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  UpIcon
} from './Select.styles'

const Item: FC<
  {
    children?: ReactNode
    ref?: Ref<HTMLDivElement> | undefined
    value: string
  } & SelectItemProps
> = forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <SelectItem {...props} ref={forwardedRef}>
      <SelectItemText>{children}</SelectItemText>
      <SelectItemIndicator className="SelectItemIndicator">
        <CheckIcon />
      </SelectItemIndicator>
    </SelectItem>
  )
})

const Select: FC<{
  ariaLabel: string
  className?: string
  defaultValue?: string
  onValueChange: (value: string) => void
  placeholder: string
  value?: string
  values: Array<{ label: string; value: string }>
}> = ({ ariaLabel, className, defaultValue, onValueChange, placeholder, value, values }) => {
  return (
    <SelectRoot defaultValue={defaultValue} onValueChange={onValueChange} value={value}>
      <SelectTrigger aria-label={ariaLabel} className={className}>
        <SelectValue placeholder={placeholder} />
        <SelectIcon>
          <DownIcon />
        </SelectIcon>
      </SelectTrigger>
      <SelectPortal>
        <SelectContent>
          <SelectScrollUpButton className="SelectScrollButton">
            <UpIcon />
          </SelectScrollUpButton>
          <SelectViewport className="SelectViewport">
            <SelectGroup>
              {values.map(({ label, value }) => (
                <Item key={`case-status-${value}`} value={value}>
                  {label}
                </Item>
              ))}
            </SelectGroup>
          </SelectViewport>
          <SelectScrollDownButton className="SelectScrollButton">
            <DownIcon />
          </SelectScrollDownButton>
        </SelectContent>
      </SelectPortal>
    </SelectRoot>
  )
}

export default Select
