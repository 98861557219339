import { log } from 'shared'

import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'

const getControversiesCount = async (): Promise<number> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id
  const userTeam = session.data.session?.user.user_metadata.team

  log('🔍 API: getControversiesCount', { onlineUserId })

  const { count, error, status } = await apiClient.rpc(
    'get_open_user_controversies',
    {
      user_id: onlineUserId,
      team_id: userTeam
    },
    { count: 'exact' }
  )
  if (isBadResponseStatus(status)) {
    throw new Error(`getControversiesCount failed with status: ${status} and error: ${error}`)
  }

  return count ?? 0
}

export default getControversiesCount
