import { Keys } from 'config'
import { log } from 'shared'
import { Delabeling } from 'types'

import apiClient from '../../apiClient'
import { isBadResponseStatus } from '../../api.helpers'
import { Database } from '../../api.types'

export const getDelabeling = async ({
  controversyId,
  userId
}: {
  controversyId?: string
  userId?: string
}): Promise<Array<Delabeling>> => {
  if (!controversyId) {
    return []
  }

  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getDelabeling')

  const { data, error, status } = await apiClient
    .from<Keys.Delabeling, Database['public']['Tables'][Keys.Delabeling]>(Keys.Delabeling)
    .select(
      `
      creator,
      labeling,
      with_labeling:labeling(controversy)
    `
    )
    .eq('with_labeling.controversy', controversyId)
    .eq('creator', userId ?? (onlineUserId || ''))

  if (isBadResponseStatus(status)) {
    throw new Error(`getDelabeling failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return []
  }

  return data.map(({ creator, labeling }) => ({
    creator,
    labeling
  }))
}
