import { FC } from 'react'
import { TabsContent, TabsList, TabsRoot } from 'components'
import { TabContentWrapper } from '../../Case.styles'
import { PrivateDocuments, PublicDocuments } from './components'
import { Wrapper } from './Documents.styles'

const Documents: FC<{ caseId: string }> = ({ caseId }) => {
  return (
    <Wrapper>
      <TabsRoot defaultValue="public">
        <TabsList
          tabs={[
            { name: 'Public', value: 'public' },
            { name: 'Private', value: 'private' }
          ]}
        />
        <TabsContent value="public">
          <TabContentWrapper>
            <PublicDocuments caseId={caseId} />
          </TabContentWrapper>
        </TabsContent>
        <TabsContent value="private">
          <TabContentWrapper>
            <PrivateDocuments caseId={caseId} />
          </TabContentWrapper>
        </TabsContent>
      </TabsRoot>
    </Wrapper>
  )
}

export default Documents
