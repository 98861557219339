import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors, Timing } from 'theme'

export const TOP_BAR_HEIGHT = '4.25rem'

export const Wrapper = styled.header`
  align-items: center;
  background: ${Colors.Surface};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: space-between;
  height: ${TOP_BAR_HEIGHT};
  width: 100%;
`

export const BackButton = styled(Link)`
  /* background: #eceff4; */
  align-items: center;
  border-right: 1px solid #e4e7ec;
  display: flex;
  height: ${TOP_BAR_HEIGHT};
  justify-content: center;
  transition: all ${Timing.TransitionControlElements};
  width: ${TOP_BAR_HEIGHT};

  &:hover {
    background: #e4e7ec;
  }
`

export const SignOutButton = styled.button`
  /* background: #eceff4; */
  border-left: 1px solid #e4e7ec;
  height: ${TOP_BAR_HEIGHT};
  width: ${TOP_BAR_HEIGHT};

  &:hover {
    background: #e4e7ec;
  }
`
