import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createDelabeling } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Delabeling } from 'types'

type Props = Delabeling & { controversy: string }

export const useCreateDelabeling = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(({ creator, labeling }: Props) => createDelabeling({ creator, labeling }), {
    onMutate: async ({ controversy, ...newLabeling }: Props) => {
      const key = [Keys.Delabeling, controversy, null]

      await queryClient.cancelQueries(key)
      const previousDelabeling = queryClient.getQueryData<Delabeling[]>(key) ?? []
      queryClient.setQueryData(key, [...previousDelabeling, newLabeling])
      return { previousDelabeling }
    },
    onError: (error, { controversy }, context) => {
      queryClient.setQueryData(
        [Keys.Delabeling, controversy, null],
        context?.previousDelabeling ?? []
      )
      logError('createDelabeling failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { controversy }) => {
      queryClient.invalidateQueries([Keys.Delabeling, controversy, null])
    }
  })
}
