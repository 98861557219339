import styled from 'styled-components'
import { Content, List, Root, Trigger } from '@radix-ui/react-tabs'
import { Colors, FontSizes } from 'theme'

export const TabsRoot = styled(Root)``

export const TabsList = styled(List)`
  border-bottom: 1px solid ${Colors.Divider};
  display: flex;
  flex-shrink: 0;
  gap: 1.25rem;
`

export const TabsTrigger = styled(Trigger)`
  align-items: center;
  color: ${Colors.TextLight};
  display: flex;
  font-size: ${FontSizes.Base};
  height: 2rem;
  justify-content: center;
  line-height: 1;
  user-select: none;

  &[data-state='active'] {
    box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
    color: ${Colors.Primary};
  }
`

export const TabsContent = styled(Content)``
