/**
 * ! Custom page, differs from dbag
 * */
import { useState } from 'react'
import { useSignInMutation } from 'data-fetcher'
import {
  Content,
  ForgotPassword,
  Form,
  Header,
  HeaderText,
  Headline,
  Logo,
  Subline,
  SubmitButton,
  TextInput,
  Wrapper
} from './Login.styles'
import { Register } from './components'
import { useLocation } from 'react-router-dom'

const Login = () => {
  const location = useLocation()
  const { search } = location
  const isRegister = search.includes('register')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isLoading: isSigningIn, mutate: signIn } = useSignInMutation()

  const handleSignIn = () => {
    signIn({ email, password })
  }

  if (isRegister) {
    return <Register />
  }

  return (
    <Wrapper>
      <Content>
        <Header>
          <Logo />

          <HeaderText>
            <Headline>Welcome back</Headline>
          </HeaderText>
        </Header>

        <Form>
          <TextInput
            inverted
            label="Username"
            onChange={input => setEmail(input)}
            placeholder="user@provider.com"
            size="large"
            type="text"
            value={email}
          />

          <TextInput
            inverted
            label="Password"
            onChange={input => setPassword(input)}
            placeholder="******"
            size="large"
            type="password"
            value={password}
          />

          <SubmitButton isLoading={isSigningIn} onClick={handleSignIn} size="large">
            Login
          </SubmitButton>
          <ForgotPassword href="mailto:info@intuitive-ai.com">Forgot your password?</ForgotPassword>
        </Form>
      </Content>
    </Wrapper>
  )
}

export default Login
