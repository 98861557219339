import { Keys } from 'config'
import { log } from 'shared'
import { Source, SourceStatus } from 'types'

import apiClient from '../../apiClient'
import { Database } from '../../api.types'
import { isBadResponseStatus } from '../../api.helpers'

export type GetSources = {
  status: SourceStatus | 'requestedOrNew'
}

export const getSources = async ({ status: sourceStatus }: GetSources): Promise<Array<Source>> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getSources', { onlineUserId })

  let query = apiClient
    .from<Keys.Sources, Database['public']['Tables'][Keys.Sources]>(Keys.Sources)
    .select('*')
    .order('name', { ascending: true })

  if (sourceStatus === 'requestedOrNew') {
    query.or('status.eq.new,status.eq.requested')
  } else {
    query.eq('status', sourceStatus)
  }

  const { data: sourcesData, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getSources failed with status: ${status} and error: ${error}`)
  }

  const sources = sourcesData?.map(
    ({ category, description, entity, id, identifier, link, name, source_type, status }) =>
      ({
        category,
        description,
        status,
        entity,
        id,
        identifier,
        link,
        name,
        sourceType: source_type
      } as Source)
  )

  return sources ?? []
}
