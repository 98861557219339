import { log } from 'shared'
import { Range, Regulation, SearchRegulationsParams } from 'types'
import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'

type Options = {
  params: SearchRegulationsParams
  range?: Range
}

export const getRegulationsBySearchTerm = async (
  options?: Options
): Promise<
  Omit<Regulation, 'checksum' | 'created_at' | 'document_json' | 'metadata_json'>[] | null
> => {
  const { range } = options || {}
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getRegulationsBySearchTerm', { onlineUserId })

  let query = apiClient
    .rpc('search_regulations', options?.params)
    .select(
      `id, title, date, fulltext, document_type, url, source, jurisdiction, authority, is_controversy, rank`
    )
    .order('date', { ascending: false })
    .limit(10)

  if (range) {
    query = query.range(range.from, range.to)
  }

  const { data, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getRegulationsBySearchTerm failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return null
  }

  return data
}
