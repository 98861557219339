import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createFingerprints } from 'api'
import type { CreateFingerprints } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Fingerprint } from 'types'

const useCreateFingerprints = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(createFingerprints, {
    onMutate: async (fingerprints: CreateFingerprints) => {
      await queryClient.cancelQueries([Keys.Fingerprints])
      const previousFingerprints =
        queryClient.getQueryData<Fingerprint[]>([Keys.Fingerprints]) ?? []
      queryClient.setQueryData([Keys.Fingerprints], [...previousFingerprints, ...fingerprints])
      return { previousFingerprints }
    },
    onError: (error, _, context) => {
      queryClient.setQueryData([Keys.Fingerprints], context?.previousFingerprints ?? [])
      logError('createFingerprints failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: () => {
      queryClient.invalidateQueries([Keys.Fingerprints])
      showToast({ description: 'Fingerprint added successfully', title: 'Success ✅' })
    }
  })
}

export default useCreateFingerprints
