export enum ControlSizes {
  Base = '2.25rem',
  Large = '2.625rem'
}

export enum FontSizes {
  Base = '0.9375rem',
  BaseButton = '0.75rem',
  BaseInput = '0.75rem',
  LargeButton = '0.875rem',
  LargeInput = '0.875rem',
  Large = '1.0625rem',
  Small = '0.875rem',
  XSmall = '0.75rem'
}
