import {
  useGetControversies,
  useGetControversiesWithRelevance,
  useGetRelevance
} from 'data-fetcher'
import { useAuth } from 'stores'

export const useGetPrevAndNextControversies = ({ controversy }: { controversy: string }) => {
  const { userId: user } = useAuth()

  const { data: relevance, isLoading: isLoadingRelevance } = useGetRelevance({
    controversy,
    user
  })

  const isIrrelevant = relevance?.relevant === false
  const isRelevant = relevance?.relevant === true

  // ! Keep isFetching
  const { data: openControversies, isFetching: isLoadingOpenControversies } = useGetControversies({
    disabled: !controversy || isLoadingRelevance || isIrrelevant || isRelevant
  })
  // ! Keep isFetching
  const { data: irrelevantControversies, isFetching: isLoadingIrrelevantControversies } =
    useGetControversiesWithRelevance({
      disabled: !controversy || isLoadingRelevance || !isIrrelevant,
      relevant: false
    })
  // ! Keep isFetching
  const { data: relevantControversies, isFetching: isLoadingRelevantControversies } =
    useGetControversiesWithRelevance({
      disabled: !controversy || isLoadingRelevance || !isRelevant,
      relevant: true
    })

  if (
    isLoadingRelevance ||
    isLoadingOpenControversies ||
    isLoadingIrrelevantControversies ||
    isLoadingRelevantControversies
  ) {
    return {
      next: '',
      prev: ''
    }
  }

  if (isIrrelevant) {
    const currentControversyIndex =
      irrelevantControversies?.findIndex(({ id }) => id === controversy) ?? 0
    const prevControversyId = irrelevantControversies?.[currentControversyIndex - 1]?.id
    const nextControversyId =
      currentControversyIndex + 1 <= (irrelevantControversies?.length ?? 0)
        ? irrelevantControversies?.[currentControversyIndex + 1]?.id
        : undefined

    return {
      next: nextControversyId ? `/controversies/${nextControversyId}` : '',
      prev: prevControversyId ? `/controversies/${prevControversyId}` : ''
    }
  }

  if (isRelevant) {
    const currentControversyIndex =
      relevantControversies?.findIndex(({ id }) => id === controversy) ?? 0
    const prevControversyId = relevantControversies?.[currentControversyIndex - 1]?.id
    const nextControversyId =
      currentControversyIndex + 1 <= (relevantControversies?.length ?? 0)
        ? relevantControversies?.[currentControversyIndex + 1]?.id
        : undefined

    return {
      next: nextControversyId ? `/controversies/${nextControversyId}` : '',
      prev: prevControversyId ? `/controversies/${prevControversyId}` : ''
    }
  }

  const currentControversyIndex = openControversies?.findIndex(({ id }) => id === controversy) ?? 0
  const prevControversyId = openControversies?.[currentControversyIndex - 1]?.id
  const nextControversyId =
    currentControversyIndex + 1 <= (openControversies?.length ?? 0)
      ? openControversies?.[currentControversyIndex + 1]?.id
      : undefined

  return {
    next: nextControversyId ? `/controversies/${nextControversyId}` : '',
    prev: prevControversyId ? `/controversies/${prevControversyId}` : ''
  }
}
