import { logError } from 'shared'
import apiClient from '../../apiClient'

const signIn = async ({ email, password }: { email: string; password: string }) => {
  const { data, error } = await apiClient.auth.signInWithPassword({ email, password })

  if (error) {
    logError('signIn failed', { errorMessage: error.message, errorName: error.name })
    throw new Error(error.message)
  }

  return data
}

export default signIn
