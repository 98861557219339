import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`

export const Content = styled.div`
  flex: 1;
  overflow-x: none;
  overflow-y: auto;
  padding: 3rem 2.5rem;
`
