import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createCaseBusinessUnit, CreateCaseBusinessUnit } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { CaseBusinessUnits } from 'types'

export const useCreateCaseBusinessUnit = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(createCaseBusinessUnit, {
    onMutate: async ({
      businessUnitId,
      businessUnitName,
      caseId
    }: CreateCaseBusinessUnit & { businessUnitName: string }) => {
      const key = [Keys.CaseBusinessUnits, caseId]

      await queryClient.cancelQueries(key)
      const previousData = queryClient.getQueryData<CaseBusinessUnits>(key) ?? []
      queryClient.setQueryData<CaseBusinessUnits>(key, [
        ...previousData,
        { businessUnitId, businessUnitName }
      ])
      return { previousData }
    },
    onError: (error, { caseId }, context) => {
      queryClient.setQueryData([Keys.CaseBusinessUnits, caseId], context?.previousData ?? [])
      logError('createCaseBusinessUnit failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { caseId }) => {
      queryClient.invalidateQueries([Keys.CaseBusinessUnits, caseId])
    }
  })
}
