import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Colors, FontSizes } from 'theme'

const Header: FC<{ componentEnd?: ReactNode; subtitle?: string; title?: string }> = ({
  componentEnd: ComponentEnd,
  subtitle,
  title
}) => (
  <Wrapper>
    <Titles>
      {Boolean(title) && <Title>{title}</Title>}
      {Boolean(subtitle) && <Subtitle>{subtitle}</Subtitle>}
    </Titles>
    {ComponentEnd}
  </Wrapper>
)

export default Header

const Wrapper = styled.div`
  display: flex;
  font-size: ${FontSizes.Large};
  justify-content: space-between;
  margin-bottom: 2em;
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: ${FontSizes.Large};
`

const Subtitle = styled.span`
  color: ${Colors.TextLight};
  font-size: ${FontSizes.Base};
  margin-top: 0.25rem;
`
