import {
  DEFAULT_FETCHER_CACHE_TIME,
  DEFAULT_FETCHER_DISABLED,
  DEFAULT_FETCHTER_KEEP_PREVIOUS_DATA,
  DEFAULT_FETCHER_STALE_TIME
} from './data-fetcher.config'
import { FetcherOptions } from './data-fetcher.types'

export const getDefaultFetcherOptions = (options: FetcherOptions = {}) => {
  return {
    cacheTime: options?.cacheTime ?? DEFAULT_FETCHER_CACHE_TIME,
    enabled: options?.disabled !== true ?? DEFAULT_FETCHER_DISABLED,
    keepPreviousData: options.keepPreviousData ?? DEFAULT_FETCHTER_KEEP_PREVIOUS_DATA,
    staleTime: options?.staleTime ?? DEFAULT_FETCHER_STALE_TIME
  }
}
