import { Card, TextInput } from 'components'
import styled from 'styled-components'
import { Colors, FontSizes } from 'theme'

export const StyledRoot = styled.main`
  display: flex;
  gap: 2rem;
`

export const StyledSidebar = styled.aside`
  width: 25%;
  @media (min-width: 1920px) {
    width: 20%;
  }
`

export const StyledSidebarCard = styled(Card)``

export const ClearAll = styled.button<{ active?: boolean }>`
  all: unset;
  font-size: ${FontSizes.XSmall};
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
  color: ${({ active }) => (active ? Colors.Primary : Colors.TextLight)};
`

export const StyledMain = styled.main`
  display: flex;
  width: 75%;
  flex-direction: column;
  gap: 2rem;
  @media (min-width: 1920px) {
    width: 80%;
  }
`

export const StyledSearchWrapper = styled(Card)`
  padding: 0.5rem;
`

export const StyledTextInput = styled(TextInput)`
  input {
    border: 0;
  }
`

export const ListRoot = styled(Card)``

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`

export const Count = styled.b`
  margin-left: 1rem;
`
