import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { Colors } from 'theme'
import { TOP_BAR_HEIGHT } from '../Layout'

const Loader: FC<{ className?: string; fullPageCenter?: boolean }> = ({
  className,
  fullPageCenter
}) => {
  return (
    <Wrapper className={className} fullPageCenter={fullPageCenter}>
      <StyledLoader />
    </Wrapper>
  )
}

export default Loader

const rotation = keyframes`
0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const Wrapper = styled.div<{ fullPageCenter?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${({ fullPageCenter }) => (fullPageCenter ? `calc(90% - ${TOP_BAR_HEIGHT})` : `unset`)};
  width: ${({ fullPageCenter }) => (fullPageCenter ? `100%` : `unset`)};
`

const StyledLoader = styled.div`
  animation: ${rotation} 1s linear infinite;
  border: 5px solid ${Colors.PrimaryLight};
  border-bottom-color: ${Colors.Primary};
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
`
