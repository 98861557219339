import styled from 'styled-components'
import { Card } from 'components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr minmax(20rem, 1fr);
  grid-gap: 1.5rem;
  padding-top: 3rem;
`

export const CardMap = styled(Card)`
  grid-row-start: 1;
  grid-row-end: 4;
`

export const MapImage = styled.img`
  width: 100%;
`
