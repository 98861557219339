import { useQuery } from '@tanstack/react-query'
import { getUsersWithLabeling } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetUsersWithLabeling = ({
  controversyId,
  options
}: {
  controversyId?: string
  options?: FetcherOptions
}) => {
  return useQuery(
    [Keys.ControversyLabelingUsers, controversyId],
    () => getUsersWithLabeling(controversyId),
    getDefaultFetcherOptions(options)
  )
}

export default useGetUsersWithLabeling
