import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteLabel } from 'api'
import { Keys } from 'config'
import { useToast } from 'stores'
import { Label } from 'types'

// Labels are cached by fingerprint id
type Props = { fingerprint: string; label: string }

const useDeleteLabel = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(({ label }: Props) => deleteLabel(label), {
    onMutate: async ({ fingerprint, label }: Props) => {
      await queryClient.cancelQueries([Keys.Labels, fingerprint])

      const previousLabels = queryClient.getQueryData<Label[]>([Keys.Labels, fingerprint]) ?? []

      queryClient.setQueryData(
        [Keys.Labels, fingerprint],
        (previousLabels ?? []).filter(({ id }) => id !== label)
      )

      return { previousLabels }
    },
    onError: (_, { fingerprint }, context) => {
      queryClient.setQueryData([Keys.Labels, fingerprint], context?.previousLabels ?? [])
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { fingerprint }) => {
      queryClient.invalidateQueries([Keys.Labels])
      queryClient.invalidateQueries([Keys.Labels, fingerprint])
      showToast({ description: 'Label deleted successfully', title: 'Success ✅' })
    }
  })
}

export default useDeleteLabel
