import { Keys } from 'config'
import { logError } from 'shared'
import { Source, SourceStatus } from 'types'

import { Database } from '../../api.types'
import apiClient from '../../apiClient'

const newSourceStatus: SourceStatus = 'new'

export type CreateSource = {
  source: Omit<Source, 'status'>
}

export const createSource = async ({ source: s }: CreateSource) => {
  const { sourceType, ...source } = s

  const { data, error } = await apiClient
    .from<Keys.Sources, Database['public']['Tables'][Keys.Sources]>(Keys.Sources)
    .insert({
      ...source,
      source_type: sourceType,
      status: newSourceStatus
    })

  if (error) {
    logError('createSource failed', { errorMessage: error.message })
    throw new Error(error.message)
  }

  return data
}
