import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createCase, CreateCase } from 'api'
import { Keys } from 'config'
import { logError } from 'shared'
import { useToast } from 'stores'
import { Case } from 'types'

const useCreateCase = () => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useMutation(createCase, {
    onMutate: async (newCase: CreateCase) => {
      const { controversy: controversyId } = newCase
      const key = [Keys.Cases, controversyId]

      await queryClient.cancelQueries(key)
      const previousCases = queryClient.getQueryData<Case[]>(key) ?? []
      queryClient.setQueryData(key, [...previousCases, newCase])
      return { previousCases }
    },
    onError: (error, { controversy: controversyId }, context) => {
      queryClient.setQueryData([Keys.Cases, controversyId], context?.previousCases ?? [])
      logError('createCase failed', { error })
      showToast({ description: 'An error occurred. Please try again.', title: 'Oh no ❌' })
    },
    onSettled: (_, __, { controversy: controversyId }) => {
      queryClient.invalidateQueries([Keys.Cases])
      queryClient.invalidateQueries([Keys.CasesPaginated])
      queryClient.invalidateQueries([controversyId])
    }
  })
}

export default useCreateCase
