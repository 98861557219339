import styled from 'styled-components'
import { Button, Card, Label as BaseLabel } from 'components'
import { Colors, FontSizes } from 'theme'

export const Wrapper = styled(Card)``

export const Heading = styled.h3`
  font-size: ${FontSizes.Small};
  margin: 2em 0 1em;
`

export const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

export const Label = styled(BaseLabel)`
  margin-bottom: 1rem;
`

export const DeleteLabelDialogContent = styled.div``

export const DeleteLabelDialogTitle = styled.span`
  display: block;
  font-size: ${FontSizes.Base};
  font-weight: 600;
  margin-bottom: 0.5rem;
`

export const DeleteLabelDialogText = styled.p`
  font-size: ${FontSizes.Small};
`

export const DeleteLabelDialogFooter = styled.footer`
  align-items: center;
  display: flex;
  gap: 0.75rem;
`

export const DeleteButton = styled(Button)`
  background: ${Colors.Destructive};
  opacity: 0.9;

  &:hover {
    background-color: ${Colors.Destructive};
    opacity: 1;
  }
`
