import { FC } from 'react'
import styled from 'styled-components'
import { Label as BaseLabel } from 'components'
import { Label as LabelType } from 'types'

const LabelList: FC<{
  activeLabelId?: string
  labels: Array<LabelType>
  onLabelClick?: (label: LabelType) => void
}> = ({ activeLabelId, labels, onLabelClick }) => (
  <Wrapper>
    {labels.map(label => {
      const { color, id, name } = label

      return (
        <Label
          active={activeLabelId === id}
          color={color}
          id={id}
          inactive={Boolean(activeLabelId && activeLabelId !== id)}
          key={`label-list-${id}`}
          name={name}
          onClick={() => onLabelClick?.(label)}
        />
      )
    })}
  </Wrapper>
)

export default LabelList

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`

const Label = styled(BaseLabel)`
  margin-bottom: 0.75rem;
`
