import { logError } from 'shared'
import apiClient from '../../apiClient'

const signOut = async () => {
  const { error } = await apiClient.auth.signOut()

  if (error) {
    logError('signOut failed', { errorMessage: error.message, errorName: error.name })
    throw new Error(error.message)
  }
}

export default signOut
