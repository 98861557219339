import { FC, ReactNode } from 'react'
import { RiArrowLeftSLine, RiLogoutBoxRLine } from 'react-icons/ri'
import { useSignOutMutation } from 'data-fetcher'
import { BackButton, SignOutButton, Wrapper } from './TopBar.styles'
import { Colors } from 'theme'

const TopBar: FC<{
  goBackHref?: string
  hideSignOut?: boolean
  leftComponent?: ReactNode
  rightComponent?: ReactNode
}> = ({ goBackHref, hideSignOut, leftComponent, rightComponent }) => {
  const { mutate: signOut } = useSignOutMutation()

  return (
    <Wrapper>
      <div>
        {Boolean(goBackHref) ? (
          <BackButton to={goBackHref!}>
            <RiArrowLeftSLine color={Colors.Text} size={22} />
          </BackButton>
        ) : leftComponent ? (
          leftComponent
        ) : null}
      </div>
      <div>
        {rightComponent}
        {!Boolean(hideSignOut) && (
          <SignOutButton onClick={() => signOut()}>
            <RiLogoutBoxRLine color={Colors.Text} size={20} />
          </SignOutButton>
        )}
      </div>
    </Wrapper>
  )
}

export default TopBar
