import { FC, ReactNode } from 'react'
import {
  TabsContent as Content,
  TabsList as List,
  TabsRoot as Root,
  TabsTrigger as Trigger
} from './Tabs.styles'

export const TabsContent: FC<{ children: ReactNode; value: string }> = ({ children, value }) => (
  <Content value={value}>{children}</Content>
)

export const TabsList: FC<{ className?: string, tabs: Array<{ name: string; value: string }> }> = ({ className, tabs }) => (
  <List className={className}>
    {tabs.map(({ name, value }) => (
      <Trigger key={`tabs-list-trigger-${name}-${value}`} value={value}>
        {name}
      </Trigger>
    ))}
  </List>
)

export const TabsRoot: FC<{ children: ReactNode; defaultValue: string }> = ({
  children,
  defaultValue
}) => <Root defaultValue={defaultValue}>{children}</Root>
