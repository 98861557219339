import React, { FC, forwardRef, ForwardedRef, useState, useEffect } from 'react'

import { Label, HiddenCheckbox, StyledCheckbox, Check } from './Checkbox.styles'

type InputElementType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type CheckboxProps = Pick<InputElementType, 'onChange' | 'checked' | 'id'> & {
  label: string
  // Used to make the label id's unique across the page
  fieldsetID: string
  className?: string
}

const sanitize = (term: string) => term.replaceAll(' ', '-').toLowerCase()

export const Checkbox: FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { onChange, checked: controlledChecked, label, fieldsetID, className },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [checked, setChecked] = useState(controlledChecked || false)
    const uniqueId = `${sanitize(fieldsetID)}-${sanitize(label)}`

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked
      setChecked(newValue)
      onChange?.(event)
    }

    useEffect(() => {
      if (controlledChecked !== undefined) {
        setChecked(controlledChecked)
      }
    }, [controlledChecked])

    return (
      <Label htmlFor={uniqueId} className={className} checked={checked}>
        <HiddenCheckbox onChange={handleChange} checked={checked} ref={ref} id={uniqueId} />
        <StyledCheckbox checked={checked}>{checked && <Check size={16} />}</StyledCheckbox>
        {label}
      </Label>
    )
  }
)
