import { Card as BaseCard, Loader as BaseLoader, EmptyNote, LoadMore } from 'components'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Colors, FontSizes } from 'theme'

export const Card = styled(BaseCard)`
  margin-top: 2rem;
`

export const Table = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
`

export const TableRow = styled.tr``

export const TableHead = styled.thead`
  ${TableRow} {
    &:hover {
      background: initial;
    }
  }
`

export const TableHeading = styled.th`
  font-size: ${FontSizes.BaseInput};
  padding: 1.25rem 0.5rem;
  text-align: left;
`

export const TableBody = styled.tbody`
  ${TableRow} {
    border-top: 1px solid ${Colors.Divider};
  }
`

export const ContentRow = styled(Link)`
  border-top: 1px solid ${Colors.Divider};
  color: ${Colors.Text};
  display: table-row;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
`

export const TableData = styled.td`
  font-size: ${FontSizes.Base};
  padding: 1.25rem 0.5rem;
`

export const TableDataInnerWrapper = styled.div`
  display: flex;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const sharedNoteStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 25vh;
  width: 100%;
`

export const NoData = styled(EmptyNote)`
  ${sharedNoteStyles};
`

export const Loader = styled(BaseLoader)`
  ${sharedNoteStyles};
`

export const CasesLoadMore = styled(LoadMore)`
  margin: 2rem 0 1rem;
`
