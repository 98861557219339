import styled from 'styled-components'
import { RiExternalLinkLine } from 'react-icons/ri'
import { Colors, FontSizes, Timing } from 'theme'

export const Label = styled.span`
  color: ${Colors.TextLight};
  font-size: ${FontSizes.Base};
  transition: ${Timing.TransitionControlElements};
`

export const Icon = styled(RiExternalLinkLine)`
  margin-bottom: 2px;
`

export const Wrapper = styled.a`
  align-items: center;
  display: inline-flex;
  gap: 0.25rem;

  &:hover {
    ${Label}  {
      color: ${Colors.TextLightHover};
    }

    ${Icon} {
      fill: ${Colors.Primary};
    }
  }
`
