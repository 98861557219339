import { useQuery } from '@tanstack/react-query'
import { getControversiesCount } from 'api'
import { Keys } from 'config'
import { FetcherOptions } from '../../data-fetcher.types'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'

const useGetOpenControversiesCount = (options?: FetcherOptions) => {
  return useQuery(
    [Keys.Controversies, 'count'],
    getControversiesCount,
    getDefaultFetcherOptions(options)
  )
}

export default useGetOpenControversiesCount
